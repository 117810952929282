import React from 'react'

// css from customInput.css
export const LoginButton = (props) => {
    const {label,className,onClick}=props
  return (
    <div className={`login-button-container ${className}`}>
      <button className='login-button' style={{cursor:"pointer"}} onClick={onClick}>{label}</button>
    </div>
  )
}
 