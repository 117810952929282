import { createSlice } from "@reduxjs/toolkit";

export const geoLocationSlice=createSlice({
    name:"locationList",
    initialState:{
        value:undefined,
    },
    reducers:{
        get_geoLocation_list:(state,action)=>{
            state.value=action.payload.geolocationData
        },
    }
})
export const {get_geoLocation_list}=geoLocationSlice.actions;
export default geoLocationSlice?.reducer