import React, { useEffect, useState } from 'react'
import '../../Css/Custom Components/productCard.css'
import { CardButton } from './CardButton'
import { motion } from 'framer-motion'
import store from '../../Redux/store'
import { useSelector } from 'react-redux'
import { add_to_cart } from '../../Redux/Package/cartSlice'
import { BorderColor } from '@mui/icons-material'
export const ProductCard = (props) => {
    const {
      backgroundColor,
      Url,
      heading,
      description,
      className,
      buttonLabel,
      clickViewMore,
      featuresCard,
      amount,
      duration,
      isPurchase,
      data,
      id,
      onClick,
      onMouseUp,
      headingColor,
      borderColor,
      color
      
    }=props
  
  
    const [isExpanded, setIsExpanded] = useState(true);

  // Function to toggle the animation state
  const toggleAnimation = () => {
    setIsExpanded((prev) => !prev);
  };

  // Start the animation loop when the component mounts
 useEffect(() => {
    const interval = setInterval(toggleAnimation, 3000); // Change the duration to control the animation speed
    return () => clearInterval(interval); // Clean up the interval when the component unmounts
  }, []); 

  
  
  
    return (
    <>
    <motion.div whileHover={{scale:1.1}} className={`product-card-container ${className}`}>
   
        <div className='product-card-top-container'>
            <div className='product-card-image-container' style={{backgroundColor:backgroundColor}} >
                <img src={Url} className="product-card-image" />
            </div>
            <p style={{color:headingColor}}>{heading}</p>
            {/* {
              !featuresCard &&
              <p style={{color:backgroundColor}}>{`${amount} / ${duration}`}</p>
            } */}
           
        </div>
        <div className='product-card-middle-container'>
           {/* <p className='product-card-middle-heading'>{heading}</p> */}
           <p className='product-card-middle-description'>{description}</p>
        </div>
        <div className='product-card-bottom-container'> 
           {
             isPurchase ?
              <CardButton onClick={onClick}  label={buttonLabel} borderColor={borderColor} color={color} />
              :
              <div>
              <p style={{marginBottom:"10px",color:backgroundColor,fontWeight:"600"}}>COMING SOON</p>
              <motion.hr 
              initial={{ width: '100%' }}
              animate={{ width: isExpanded ? '2%' : '100%' }}
              transition={{ duration: 3, loop: Infinity }}
              style={{backgroundColor:backgroundColor,border:"none",height:"2px",
                      position: 'relative',
                      left: '50%',
                      transform: 'translateX(-50%)',}} 
              className='coming-soon-hr'/>
            </div>
           }
           

        </div>
        {/* {
        !featuresCard &&
        <div className='product-card-bottom-container'>
          {
            isPurchase ?
            <>
              <span className='product-card-view-span' onClick={clickViewMore}>View details</span>
              <CardButton onClick={onClick}  label={buttonLabel} backgroundColor={backgroundColor}/>         
            </>:
            <div>
              <p style={{marginBottom:"10px",color:backgroundColor,fontWeight:"600"}}>COMING SOON</p>
              <motion.hr 
              initial={{ width: '100%' }}
              animate={{ width: isExpanded ? '2%' : '100%' }}
              transition={{ duration: 3, loop: Infinity }}
              style={{backgroundColor:backgroundColor,border:"none",height:"2px",
                      position: 'relative',
                      left: '50%',
                      transform: 'translateX(-50%)',}} 
              className='coming-soon-hr'/>
            </div>
          }
        </div> 
        } */}
       
    </motion.div>
    </>
  )
}
