import axios from "axios";
import { HEADERS } from "../UrlAndPaths";

export const addNewUserAPICall = async (
    body,setSuccess
  ) => {
    await axios
      .post("customer/register", body, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true)
        }
      })
      .catch((err) => {
       console.error(err);
      });
  };