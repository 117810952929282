import React, { useRef } from "react";
import "../../Css/Custom Components/productDetailsCard.css"
import restaurantIcon from "../../Assets/Icons/Restuarent.svg"
import erpImage1 from "../../Assets/Images/admin-dash.png"
import erpImage2 from "../../Assets/Images/empdash.png"
import { motion } from "framer-motion";
import RightArrowSvg from '../../Assets/Icons/arrow-right-white.svg'
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Dialog, MenuItem, Select } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LogisticsSvg from "../../Assets/Icons/Logistics.svg";
import HotelSvg from "../../Assets/Icons/hotel_1_.svg";
import EducationSvg from "../../Assets/Icons/mortarboard.svg";
import RestaurantSvg from "../../Assets/Icons/Restuarent.svg";
import TextileSvg from "../../Assets/Icons/sewing-machine_2_.svg"
import AviationSvg from "../../Assets/Icons/delivery_1_.svg"
import hospitalIcon from "../../Assets/Icons/hospital_1_.svg";
import superMarket from "../../Assets/Icons/superMarket.svg";
export const ProductDetailsCard=(props)=>{
    const{
        closeClick,
        title,
        logo,
        images,
        description,
        moduleIncl,
        data
    }=props;
    
    const scrollRef = useRef(null);

  const scrollToLeft = (scrollDiv, step) => {
    scrollDiv.scrollLeft -= step;
    
    if (scrollDiv.scrollLeft <= 0) {
    //   scrollDiv.scrollLeft = scrollDiv.scrollWidth;
      console.log("helo");
    }
    scrollRef.current = requestAnimationFrame(() => scrollToLeft(scrollDiv, step));
  };

  const handleMouseEnter = (icon) => () => {
    const scrollDiv = document.getElementById('productDetailImageCtn');
    const step = 500; // Adjust the scroll step as needed
    scrollToLeft(scrollDiv, icon === 'right' ? -step : step);
  };

  const handleMouseLeave = () => {
    cancelAnimationFrame(scrollRef.current);
  };
    return(
        <>

            <div className="product-details-parent">
            <Dialog open={true} className="product-details-dialog">
                <div className="product-details-container">
                    <div className="product-detail-topbar">
                        <div 
                            style={{
                                display:"flex",
                                alignItems:"center",
                             
                                }}>
                                    <div 
                                    className="product-single-view-image"
                                    style={{
                                        backgroundColor:
                                        data.packageName==="ERP-Restaurant"?
                                        "#ff00cc"
                                        :data.packageName==="ERP-Textile"?
                                        "#0eaf6c"
                                        :data.packageName==="ERP-Logistics"?
                                        "#ff0009"
                                        :data.packageName==="ERP-Hotel"?
                                        "#e3eb00"
                                        :data.packageName==="ERP-Education"?
                                        "#004cff"
                                        :data.packageName==="ERP-Aviation"?
                                        "#8a00e5"
                                        :data.packageName==="ERP-Hospital"?
                                        "rgb(255 125 33)"
                                        :data.packageName==="ERP-SuperMarket"?
                                        "#04969B"
                                        :undefined
                                    }}
                                    >
                                    <img 
                           
                            src={
                                 data.packageName==="ERP-Restaurant"?
                                 RestaurantSvg
                                 :data.packageName==="ERP-Textile"?
                                 TextileSvg
                                 :data.packageName==="ERP-Logistics"?
                                 LogisticsSvg
                                 :data.packageName==="ERP-Hotel"?
                                 HotelSvg
                                 :data.packageName==="ERP-Education"?
                                 EducationSvg
                                 :data.packageName==="ERP-Aviation"?
                                 AviationSvg
                                 :data.packageName==="ERP-Hospital"?
                                 hospitalIcon
                                 :data?.packageName==="ERP-SuperMarket"?
                                 superMarket
                                 :undefined
                            } alt="" />
                                    </div>
                          
                            <h2>{data?.packageName}</h2>
                        </div>
                        <CloseIcon 
                            onClick={closeClick}
                            sx={{fontSize:"2rem",cursor:"pointer"}}/>

                    </div>
                    <div className="product-detail-img-section">
                    <motion.div 
                        className="product-detail-image-ctn"
                        id="productDetailImageCtn"
                        >
                        <img src={erpImage1} alt="" />
                        <img src={erpImage2} alt="" />
                        <img src={erpImage1} alt="" />
                        <img src={erpImage2} alt="" />
                        <img src={erpImage1} alt="" />
                    </motion.div>
                        <div className="purchase-products-card-container-left-scroll" 
                        // onClick={handleMouseEnter("right")}
                        onMouseEnter={handleMouseEnter('right')}
                        onMouseLeave={handleMouseLeave}
                        >
                        
                            <ArrowForwardIosIcon />
                        </div>
                        <div 
                        className="purchase-products-card-container-right-scroll" 
                        // onClick={handleMouseEnter("left")}
                        onMouseEnter={handleMouseEnter('left')}
                        onMouseLeave={handleMouseLeave}
                        >
                            <ArrowBackIosNewIcon />
                        </div>
                    </div>
                    <div className="product-detail-description-ctn">
                        <p>Description</p>
                        <hr className="global-hr" />
                        {/* <p>{data?.description}</p> */}
                        <div className="product-detail-description-body">
                            <div className="product-detail-description-body-left">
                                <p className="description-para">
                                {data?.description}
                               </p>
                                <p style={{fontWeight:"700",fontSize:"1rem"}}>Modules Includes</p>
                                {
                                    data?.module?.map((r,i)=>(
                                        <p className="description-para-list">- {r?.moduleName==="STAFF"?"HRM":r?.moduleName}</p>
                                    ))
                                }
                               
                            </div>
                            <div className="product-detail-description-body-right">
                                <div className="product-detail-description-body-right-white-section">
                                    <div className="description-white-ctn-section" style={{border:"none"}}>
                                        <p style={{fontSize:"1rem"}}>{`${data?.sellingPrice} / ${data?.durationType}`}</p>
                                        {/* <p className="description-para">
                                        orem Ipsum has been the industry's standard dummy 
                                        text ever since the 1500s, when an unknown printer 
                                        took a galley of type and scrambled it to make.

                                        </p> */}
                                    </div>
                                    {/* <div className="description-white-ctn-section" style={{border:"none"}}>
                                        <p style={{fontSize:"1rem"}}>Rs.3500/Month</p>
                                        <p className="description-para">
                                        orem Ipsum has been the industry's standard dummy 
                                        text ever since the 1500s, when an unknown printer 
                                        took a galley of type and scrambled it to make.
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="product-detail-description-ctn">
                        <p>Add On Packages</p>
                        <hr className="global-hr" />
                        <div className="product-detail-description-body-left">
                            <p className="description-para-list">- Inventory</p>
                            <p className="description-para-list">- HRM</p>
                        </div>
                    </div>
                    <div className="product-detail-rating-ctn">
                        {/* <div className="custom-login-input" style={{width:"auto"}}>
                            <Select value="1">
                                <MenuItem value="1">3500/month</MenuItem>
                            </Select>
                        </div> */}
                        {/* <button className="products-card-btn">
                            <p>Add to Cart</p>
                            <motion.img src={RightArrowSvg} className="products-card-btn-image"  />
                        </button> */}
                    </div>
                </div>
            </Dialog>
            </div>
        </>
    )
}