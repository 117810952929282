import axios from "axios"
import { HEADERS } from "../UrlAndPaths"
import { deleteCartByCusIdApiCall } from "../Cart/cartAPI";
import { deletePackageAPICall } from "../Package/packageAPI";
import store from "../../Redux/store";
import { get_my_plans_singleView } from "../../Redux/Profile/myPlansSlice";

// export const AddOrderApiCall=(body)=>{
//     axios.post("purchase/addPurchase",body,HEADERS)
//     .then((res)=>{
//         if(res.status===200){
//             console.log(res);
//         }
//     })
//     .catch((err)=>{
//         console.log(err.response);
//     })
// }

const initPayment = (data,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,body) => {
  
   console.log(data);
    const options = {
        
        key: "rzp_test_JpK3Z3FmqDcSW6",
        amount: data.amount,
        currency: data.currency,
        // name: book.name,
        // description: "Test Transaction",
        // image: book.img,
        order_id: data.id,
        handler: async (response) => {
            console.log(response);
            try {
                const verifyUrl = body?.isRenewal === true ? "purchase/renewpackage":"purchase/verifyPayment";
                const { data } = await axios.post(verifyUrl, response);
                console.log(data);
                deleteCartByCusIdApiCall(updateListner)
                setSnackMsg(data.msg)
                setOpenSuccessSnackbar(true)
               
            } catch (error) {
                console.log(error);
                setSnackMsg(error.msg)
                setOpenErrorSnackbar(true)
            }
        },
        theme: {
            color: "#3399cc",
           
        },
      
    };
   

    const rzp1 =  window.Razorpay(options);
    rzp1.open();
};



export const AddOrderApiCall= async(
    body,
    updateListner,
    setOpenSuccessSnackbar,
    setOpenErrorSnackbar,
    setSnackMsg
    )=>{
     console.log(body);   
    try {
        const orderUrl = "purchase/addPurchase";
        const { data } = await axios.post(orderUrl,body);
        console.log(data);
        initPayment(data,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,body);
    } catch (error) {
        console.log(error);
    }
}

export const refundAPICall=(body,updateListner,setCancelPopup,setRefundConfirm)=>{
    axios.post("purchase/refundpayment",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           console.log('add');
           updateListner()
           setCancelPopup(false)
           setRefundConfirm(true)
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}


const renewPayment = (data,addPackageBody,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg) => {
  
    console.log(data);
     const optionss = {
         
         key: "rzp_test_JpK3Z3FmqDcSW6",
         amount: data.amount,
         currency: data.currency,
         // name: book.name,
         // description: "Test Transaction",
         // image: book.img,
         order_id: data.id,
         handler: async (response) => {
            response.item=addPackageBody
            // response={...response,...addPackageBody}
             console.log(response);
             try {
                 const verifyUrl ="purchase/renewpackage";
                 const { data } = await axios.post(verifyUrl, response);
                 console.log(data);
                 deleteCartByCusIdApiCall(updateListner)
                 setSnackMsg(data.msg)
                 setOpenSuccessSnackbar(true)
                 localStorage.removeItem('planId')

                
             } catch (error) {
                 console.log(error);
                 setSnackMsg(error.msg)
                 setOpenErrorSnackbar(true)
             }
         },
         theme: {
             color: "#3399cc",
            
         },
       
     };
    
 
     const rzp2 =  window.Razorpay(optionss);
     rzp2.open();
 };
 
// renew api 
export const renewApiCall=async(body,addPackageBody,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg)=>{
    try {
        const orderUrl = "purchase/renewpayment";
        const { data } = await axios.post(orderUrl,body);
        console.log(data);
        renewPayment(data,addPackageBody,updateListner,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg);
    } catch (error) {
        console.log(error);
    }
}



//// new payment api 
export const addCustomerPurchaseAPICall=(
    body,
    setOpenSuccessSnackbar,
    setOpenErrorSnackbar,
    setSnackMsg 
)=>{
    axios.post("customer/addcustomerpurchase",body,HEADERS)
    .then((res)=>{
        console.log('api call success');
        setSnackMsg(res.data)
        setOpenSuccessSnackbar(true)
    })
    .catch((err)=>{
        // setSnackMsg(err.response.data?.error)
        setOpenErrorSnackbar(true)
    })
}

// free trial
export const freeTrialAPICall=(body,
    setOpenSuccessSnackbar,
    setOpenErrorSnackbar,
    setSnackMsg 
)=>{
    // setLoadingForm(true)
     axios.post("admin/add7daymanual",body,HEADERS)
     .then((res)=>{
        if(res.status===200){
        //   setLoadingForm(false)
        setSnackMsg(res.data)
        setOpenSuccessSnackbar(true)
        localStorage.removeItem("isFreetrial")
        }
     })
     .catch((err)=>{
    //   setLoadingForm(false)
    setSnackMsg(err.response.data)
    setOpenErrorSnackbar(true)
     })
  
  }

  export const viewPlansSingleAPICall=(body)=>{
    axios.post("package/viewoutletSingleForcart",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           store.dispatch(get_my_plans_singleView({singleViewData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
  }

  export const  addOutletAPICall=(
    body,
    setOpenSuccessSnackbar,
    setOpenErrorSnackbar,
    setSnackMsg 
)=>{
  axios.post("admin/renewbranchManual",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        setSnackMsg(res.data)
        setOpenSuccessSnackbar(true)
         localStorage.removeItem("singleViewId")
    }
  })
  .catch((err)=>{
    setSnackMsg(err.response.data)
    setOpenErrorSnackbar(true)
  })
  }

