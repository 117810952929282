import axios from "axios";
import { HEADERS } from "../UrlAndPaths";

export const updateUserProfileAPICall=async(body,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,isLoading,clickSkip)=>{
    await axios.put("customer/updateCustomerProfile",body,HEADERS)
            .then((res)=>{
                if(res.status===200){
                    setSnackMsg("Profile edited")
                    setOpenSuccessSnackbar(true)
                    isLoading(false)
                    clickSkip!==undefined && clickSkip()
                }
            })
            .catch((err)=>{
                console.error(err.res);
                setSnackMsg("Something went wrong !!")
                    setOpenErrorSnackbar(true)
                    isLoading(false)
            })
}