import React,{useRef, useState} from "react";
import TopBar from '../../TopBar'
import '../../../Css/Login/login.css'
import loginImage from '../../../Assets/Images/New/Group 10962.svg'
import logo from '../../../Assets/Images/eyet-logo-b.png'
import successAlert from '../../../Assets/Icons/successIcon.png'
import { CustomInput } from "./CustomInput";
import { LoginButton } from "./LoginButton";
import { useNavigate } from "react-router";
import { addNewUserAPICall } from "../../../API/Login/registerUserAPI";
import { Dialog } from "@mui/material";
import { useTranslation } from 'react-i18next';
export const NewSignup=()=>{
  const { t } = useTranslation();


    const emailRef=useRef(null)
    const nameRef=useRef(null)
    const mobileRef=useRef(null)  
    const companyNameRef=useRef(null)  

    const registerUserInitialState = {  
      email: "",
      name: "",
      mobile: "",
      companyName: ""
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const[registerUserForm,setRegisterUserForm]=useState(registerUserInitialState);
    const [successPopup,setSuccessPopup]=useState(false)
    const [termsAndConditionPopup,setTermsAndConditionPopup]=useState(false)
    const getRegisterForm = (key) => (e) => {
      const { value } = e.target;
      
      if (key === "email") {
        setRegisterUserForm({ ...registerUserForm, email: value });
        setErrorLabelEmail(false)
        if(value===""){
          setErrorLabelEmail(true)
        }
        if (!emailRegex.test(value)) {
          setErrorLabelEmail(true)
        }
        else{
          setErrorLabelEmail(false)
        }
      }
      if (key === "name") {
        setRegisterUserForm({ ...registerUserForm, name: value });
        setErrorLabelName(false)
        if(value===""){
          setErrorLabelName(true)
        }
      }
      if (key === "mobile") {
        setRegisterUserForm({ ...registerUserForm, mobile: value });
        setErrorLabelMobile(false)
        if(value===""){
          setErrorLabelMobile(true)
        }
      }
      if (key === "companyName") {
        setRegisterUserForm({ ...registerUserForm, companyName: value });
        setErrorLabelCompany(false)
        if(value===""){
          setErrorLabelCompany(true)
        }
      }
    };

    const addNewUser = () => {

        if(registerUserForm?.email===""){
          setErrorLabelEmail(true)
        }
        else if(registerUserForm?.name===""){
          setErrorLabelName(true)
        }
        else if(registerUserForm?.mobile===""){
          setErrorLabelMobile(true)
        }
        else if(registerUserForm?.companyName===""){
          setErrorLabelCompany(true)
        }
        else{
          addNewUserAPICall(
            addNewUserBody,setSuccessPopup
          );
        }
    };


    let navigate = useNavigate();
    function handleClicktoProfile() {
        navigate('/signin')
    }
    
    const addNewUserBody = {
      email: registerUserForm?.email,
      name: registerUserForm?.name,
      mobile: registerUserForm?.mobile,
      companyName: registerUserForm?.companyName  
    };

    const[errorLabelName,setErrorLabelName]=useState(false)
    const[errorLabelEmail,setErrorLabelEmail]=useState(false)
    const[errorLabelMobile,setErrorLabelMobile]=useState(false)
    const[errorLabelCompany,setErrorLabelCompany]=useState(false)
    
    const handleKeyDown=(e)=>{
      if(e.key==="Enter"){
        console.log(emailRef);
        e.preventDefault()
        if(e.target===emailRef.current){
          nameRef.current.focus()
        } else if(e.target===nameRef.current){
          mobileRef.current.focus()
        } else if(e.target===mobileRef.current){
          companyNameRef.current.focus()
        } else if(e.target===companyNameRef.current){
          addNewUser()
        }
      }
  }
    return(
        <>
        {/* <TopBar/> */}
      <div className='login-container'>
      <div className='login-sub-container'>
      <div  className='login-right-container'>
          <img  src={loginImage}/>
      </div>
      <div  className='login-left-container'>
      <div className="login-card-container">
    <div className="login-card-top-container">
      {/* <img src={logo} /> */}
      <h3>{t("Sign Up Your Account")}</h3>
      <p>{t("New SignUp ? Register for an Account")}</p>
    </div>
    <div className="login-card-form-container" >
       <div className="new-signup-form-section">
        <CustomInput 
          onChange={getRegisterForm("email")} 
          value={registerUserForm?.email} 
          label={t("email")}
          type="email" 
          refer={emailRef}
          onKeyDown={handleKeyDown}
          />
          {errorLabelEmail? <label className="custom-input-validation">{t("pleasefillthesefield")}</label>
          :""}  
       </div>
       <div className="new-signup-form-section"> 
        <CustomInput         
          onChange={getRegisterForm("name")} 
          value={registerUserForm?.name} 
          label={t("name")}
          type="name"
          refer={nameRef}
          onKeyDown={handleKeyDown}
          />
          {errorLabelName? <label className="custom-input-validation">{t("pleasefillthesefield")}</label>
          :""}  
       </div>
       <div className="new-signup-form-section">
        <CustomInput 
          onChange={getRegisterForm("mobile")} 
          value={registerUserForm?.mobile} 
          label={t("mobile")}
          type="tel" 
          refer={mobileRef}
          onKeyDown={handleKeyDown}
          />
          {errorLabelMobile? <label className="custom-input-validation">{t("pleasefillthesefield")}</label>
          :""}  
       </div>
       <div className="new-signup-form-section"> 
        <CustomInput 
         onChange={getRegisterForm("companyName")} 
         value={registerUserForm?.companyName} 
         label={t("companyname")} 
         type="name"
         refer={companyNameRef}
         onKeyDown={handleKeyDown}
         />
          {errorLabelCompany? <label className="custom-input-validation">{t("pleasefillthesefield")}</label>
          :""}  
       </div>
            
      {/* <CustomInput label="Company Code"/> */}
  
    </div>
     <div className="login-card-button-container"> 
    
      <LoginButton label={t("SignUp")} onClick={addNewUser} />
      <p>
        {t("If you already have an Account,please")} <br/><span className='login-footer-link' onClick={handleClicktoProfile}>{t("SignIn")}</span> {t("to continue")}
      </p>
    
    </div>
    <div className="terms-and-condition-container">
      <input type="checkbox" />
      <label htmlFor="" onClick={()=>setTermsAndConditionPopup(true)}>{t("Accept Terms & Conditions")}</label>
    </div>
    {/* <div className='login-card-footer-container'>
   </div> */}
  
    </div>
      </div>
    
      </div>
      </div>

      <Dialog open={successPopup} maxWidth="lg" onClose={handleClicktoProfile}>
          <div className="signup-suceess-popup">
              <img src={successAlert} alt="" />
              <p>Your credentials, username,
                 and password have been sent to
                 your email.</p>
          </div>
      </Dialog>

      <Dialog open={termsAndConditionPopup} maxWidth="lg" >
          <div className="terms-and-condition-container-main">
            <div className="terms-and-condition-popup">
                  <div onClick={()=>setTermsAndConditionPopup(false)} className="terms-and-condition-popup-close-container">
                    <i class="bi bi-x"></i>
                  </div>
                 <h5>Terms And Conditions</h5>
                 <div className="terms-and-condition-content">
                    <p></p>
                 </div>
                 <div style={{display:"flex",justifyContent:"space-around"}}>
                   <button className="btn-primary">Accept</button>
                   <button onClick={()=>setTermsAndConditionPopup(false)} className="btn-primary-outlined">Cancel</button>
                 </div>
             </div>  
          </div>
         
       </Dialog>
        </>
    );
};