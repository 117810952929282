import { createSlice } from "@reduxjs/toolkit";

export const packageSlice=createSlice({
    name:"packageList",
    initialState:{
        value:undefined,
        cartValue:undefined,
        filterPackage:undefined,
        packageCustomer:undefined
    },
    reducers:{
        get_package_list:(state,action)=>{
            state.value=action.payload.packageData
        },
        get_cart_list:(state,action)=>{
            state.cartValue=action.payload.cartData
        },
        get_filter_package:(state,action)=>{
            state.filterPackage=action.payload.filterPackageData
        },
        get_all_package_for_website:(state,action)=>{
            state.packageCustomer=action.payload.packageCustomerData
        }
    }
})
export const {get_package_list,get_cart_list,get_filter_package,get_all_package_for_website}=packageSlice.actions;
export default packageSlice?.reducer