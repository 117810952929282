import React from 'react'
import RightArrowSvg from '../../Assets/Icons/arrow-right-white.svg'
import { motion } from 'framer-motion'
// css from custom components  button.css 
export const CardButton = (props) => {
    const {label,backgroundColor,onClick,borderColor,color}=props
  return (
    <>
     <motion.button onClick={onClick} whileHover={{scale:1.1}} className='products-card-btn' style={{borderColor:borderColor}} >
       <p  style={{color:color}}>{label}</p>
       {/* <motion.img src={RightArrowSvg} className="products-card-btn-image"  /> */}
       {/* <RightArrowSvg/> */}
     </motion.button>
    </>
  )
}
