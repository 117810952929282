import axios from "axios"
import store from "../../Redux/store"
import { get_free_trial_data, get_my_plans } from "../../Redux/Profile/myPlansSlice"
import { HEADERS } from "../UrlAndPaths"

export const myPlansAPICall=()=>{
    axios.post("customer/viewcustomerplans")
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_my_plans({myPlansData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

export const freeTrialDurationAPICall=()=>{
    axios.get("package/getPackageDuration",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_free_trial_data({freeTrialData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}