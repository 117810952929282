// import { AES, SHA512 } from 'crypto-js';
import CryptoJS,{SHA512} from 'crypto-js'; 



    

    
    export const encrypt=(text,skey) =>{
        var base64Iv = "0123456789abcdef"; // generate your key
        var key = CryptoJS.enc.Base64.parse(skey);
        var iv = CryptoJS.enc.Utf8.parse(base64Iv);
        var encrypted = CryptoJS.AES.encrypt(text, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        var decryptedData = encrypted.toString();
        return decryptedData;
    }

    export const decrypt=(text, skey)=>{
      var base64Iv = "0123456789abcdef";
      var key = CryptoJS.enc.Base64.parse(skey);
      var iv = CryptoJS.enc.Utf8.parse(base64Iv);
      var decrypted = CryptoJS.AES.decrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      var decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    

    export const checksum=(data)=>{
        return SHA512(data).toString();
    }







