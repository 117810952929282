import React from 'react'
import '../../Css/Custom Components/buttons.css'
import { motion } from 'framer-motion'
const ContainedButton = (props) => {
const {label,backgroundColor,fontSize,fontWeight,onClick,width,marginLeft}=props

  return (
    <>
    <button  onClick={onClick} className='btn cta-contained-btn' style={{backgroundColor:backgroundColor,fontSize:fontSize,fontWeight:fontWeight,width:width,marginLeft:marginLeft}}>
        {label}
    </button>
    </>
  )
}

export default ContainedButton