import React, { useEffect, useState } from "react";
import Home from "../Home/Home";
import ingImage from "../../../Assets/Images/Web-View.png";
import mobileIngImage from "../../../Assets/Images/Mobile-View.png";
import Fireworks from "@fireworks-js/react";
import ReactAudioPlayer from 'react-audio-player';
import { useNavigate } from "react-router";

export const Qrcode = () => {
  let navigate = useNavigate();



  const [image, setImage] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setImage(false);
    }, [8000]);
  }, []);


  const style = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "fixed",
  };


  //
  const audioUrl = 'https://fireworks.js.org/sounds/explosion0.mp3';

  useEffect(() => {
    const audio = new Audio(audioUrl);
    
    // Play the audio
    audio.play();

    // Optionally, you can stop the audio after a certain duration
    
    setTimeout(() => {
      audio.pause();
      audio.currentTime = 0;
    }, 5000); // Stop after 5 seconds
  }, []);

//

return image ?
   (
    <>
    
      <div className="inaugration-image">
        <img className="desktop-img" src={ingImage}></img>
        <img className="mobile-img" src={mobileIngImage}></img>
      </div>
      <div className="fireWorks">
        <Fireworks
          style={style}
        //   sound={{ files: [audioUrl] }}
          acceleration={1}
        />
      </div>
     
      
    </>
   
  ) : (
    //  <Home />
    navigate("/")
   );

// return (
//   <>
 
//      <div className="inaugration-image">
//         <img className="desktop-img" src={ingImage}></img>
//         <img className="mobile-img" src={mobileIngImage}></img>
//       </div>
//       <div className="fireWorks">
//         <Fireworks
//           style={style}
//         //   sound={{ files: [audioUrl] }}
//           acceleration={1}
//         />
//       </div>
//       </>
// )
};
