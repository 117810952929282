import { createSlice } from "@reduxjs/toolkit";

export const cartSlice=createSlice({
    name:"cartSlice",
    initialState:{
        value:[]
    },
    reducers:{
        add_to_cart:(state,action)=>{
        const item = state.value.find((item)=>item._id===action.payload.cartList._id
        )
    
        if(item){
           
            console.log('item exist');
            // item.quantity =item.quantity+action.payload.quantity;
        }
        else{
            console.log("new");
            state.value.push(action.payload.cartList)
        }
        },
        remove_cart_item:(state,action)=>{
            state.value=state.value.filter((item)=>item._id !== action.payload._id )
        }
    }
})

export const {add_to_cart,remove_cart_item}=cartSlice?.actions;
export default cartSlice?.reducer;