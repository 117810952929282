import { createSlice } from "@reduxjs/toolkit";

export const countryListSlice=createSlice({
    name:"countryListSlice",
    initialState:{
        value:undefined,
    },
    reducers:{
        get_previous_country_list:(state,action)=>{
            state.value=action.payload.prevCountry
        },
    }
})
export const {get_previous_country_list}=countryListSlice.actions;
export default countryListSlice?.reducer