import React from 'react'
import '../../../Css/Login/loginFooter.css'
import { Google } from '@mui/icons-material'
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
export const LoginFooter = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  function handleClicktoProfile() {
    navigate('/signup')
  }
  const clickForgotPass=()=>{
    navigate('/forgotpass')
  }
  return (
    <div className='login-footer-container'>
        <p>{t("Don't have an account")} <span  className='login-footer-link' onClick={handleClicktoProfile}>{t("SignUp")}</span></p>
        <p onClick={clickForgotPass} className='login-footer-forgot-pass'>{t("forgotpassword")}</p>
        {/* <div className='google-sign-in-container'>
            <p><span><Google/></span> Sign In With Google</p>
        </div>
        <h5>
        100% Safe & Secure! <br/>Trusted By 10,000+ Manufacturing
        </h5> */}
    </div>
  )
}
