let date = new Date();
const currentDay = `0${new Date().getDate()}`.slice(-2);
const currentMonth = `0${new Date().getMonth() + 1}`.slice(-2);
const currentYear = new Date().getFullYear();
const currentHour = date.getHours();
const currentMinutes = String(date.getMinutes()).padStart(2, "0");
const currentSeconds = date.getSeconds();

var newDate = new Date().toISOString();

export const today = `${currentYear}-${currentMonth}-${currentDay}`;
export const currentTime = `${currentHour}:${currentMinutes}`;

export const getCurrentTime = () => {
  let dateFn = new Date();
  let currentTime = "00:00:00";
  const currentHour = dateFn.getHours();
  const currentMinutes = String(dateFn.getMinutes()).padStart(2, "0");
  const currentSeconds = String(dateFn.getSeconds()).padStart(2, "0");
  currentTime = `${currentHour}:${currentMinutes}:${currentSeconds}`;
  return currentTime;
};
//Convert time format from YYYY-MM-DD to DD-MM-YYYY
export const convertDateFormat = (input) => {
  if (input !== undefined) {
    let datePart = input.match(/\d+/g);
    let year = datePart !== null && datePart[0], // get 4 digits
      month = datePart !== null && datePart[1],
      day = datePart !== null && datePart[2];

    return day + "/" + month + "/" + year;
  }
};
//export const updateDate = (new Date(newDate.setDate(newDate.getDate() - 10)).toDateString())
//export const updateDate = newDate.setDate(newDate.getDate() - parseInt(10));
export const previousDate = new Date(
  new Date().setDate(new Date().getDate() - 30)
)
  .toISOString()
  .split("T")[0];



const date1 = new Date();

let day = date.getDate();
let month = date.getMonth() + 1;
let year = date.getFullYear();

export let currentDate = `${day}-${month}-${year}`