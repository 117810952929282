import React from "react";
import "../Css/Footer/footer.css";
import Logo from "../Assets/Images/eyet-02.svg";
import fb from "../Assets/Images/Facebook.svg"
import x from "../Assets/Images/x-social-media-black-icon (1).svg"
import git from "../Assets/Images/Github.svg"
import instagram from "../Assets/Images/Insta.svg"
import linkdin from "../Assets/Images/Linkdin.svg"
import palystore from "../Assets/Images/Group 10536.png"
import appstore from "../Assets/Images/Group 10537.png"
import { useTranslation } from 'react-i18next';

export const Footer = () => {
   const { t } = useTranslation();
  return (
    <>
      <footer>
       
        
        <div className="footer-container">
          <img src={Logo} alt="Logo" className="logo" style={{width:"110px",marginTop:"1%"}} />
          <div className="footer-content-container">
              <div className="footer-content-container-single">
               
                     {/* <div className="footer-appstore-image-conatiner">
                        <img src={palystore} alt="" />
                        <img src={appstore} alt="" />
                     </div> */}
              </div>
              <div className="footer-content-container-single">
                 <div className="footer-list-container">
                    <div className="footer-list-single-container">
                        <h5>{t("home")}</h5>
                        <p>{t("pricing")}</p>
                        <p>{t("help")}</p>
                        <p>{t("Privacy")}</p>
                    </div>
                    <div className="footer-list-single-container">
                        <h5>{t("products")}</h5>
                        <p>{t("erprestaurant")}</p>
                        <p>{t("erptextile")}</p>
                        <p>{t("erplogistic")}</p>
                        <p>{t("erphotel")}</p>
                        <p>{t("erpeducation")}</p>
                        <p>{t("erpAVIATION")}</p>
                        <p>{t("erphospital")}</p>
                    </div>
                    <div className="footer-list-single-container">
                        <h5>{t("ourmenu")}</h5>
                        <p>{t("inventory")}</p>
                        <p>{t("accounting")}</p>
                        <p>{t("hrm")}</p>
                        <p>{t("pos")}</p>
                        
                    </div>
                   
                 </div>
                 <div className="footer-list-button-icon">
                   {/* <div className="footer-list-button-subscribe">
                      <input type="text" placeholder="SUPPORT@LEEYET.COM"/>
                      <button>Subscribe</button>
                   </div> */}
                   <div className="footer-list-icon">
                        <a href="https://ms-my.facebook.com/LeEYETtechnohub/"><img src={fb} alt="" /></a>
                        <a href="https://twitter.com/LEEYE_T"><img src={x} alt="" /></a>
                        <a href="https://www.instagram.com/leeyet1/"> <img src={instagram} alt="" /></a>
                        <a href="https://github.com/leeyettechno"><img src={git} alt="" /></a>
                        <a href="https://www.linkedin.com/in/leeye-t-techno-hub-llp"><img src={linkdin} alt="" /></a>
                   </div>
                 
                 </div>
              </div>
          </div>

          <div className="footer-container-bottom-container">
             <p>{t("eyet")} | {t("allrights")}</p>
          </div>
        
      
        </div>
       
      
         {/* <div className="footer-icon-container">
         
            <a href="https://ms-my.facebook.com/LeEYETtechnohub/"><img src={fb} alt="" /></a>
            <a href="https://twitter.com/LEEYE_T"><img src={twitter} alt="" /></a>
            <a href=""> <img src={whatsapp} alt="" /></a>
            <a href="https://www.instagram.com/leeyet1/"><img src={instagram} alt="" /></a>
            <a href=""><img src={youtube} alt="" /></a>
           
            
         
         </div> */}
       
       
     
      </footer>
    </>
  );
};
