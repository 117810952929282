import React, { useEffect, useState } from 'react'
import "../Css/Custom Components/topbarSelect.css"
import ReactFlagsSelect from 'react-flags-select';
import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import { languages } from 'language-list';
import { useSelector } from 'react-redux';
import CountryListCode from "../Components/Custom Components/countryCodeWithFull.json"
import { changeBaseUrl } from '../API/UrlAndPaths';
import currencyCodes from 'currency-code-map';
import i18n from '../Components/JS/i18n';
import { useTranslation } from 'react-i18next';
export const TopBarSelect = () => {
  const { t } = useTranslation();
  const language=localStorage.getItem("ln")
  const country=localStorage.getItem("country")
  const languageList=[
    {code:"en",name:"English",ln:"EN"},
    {code:"ar",name:"Arabic",ln:"AR"},
    {code:"fr",name:"French",ln:"FR"},
  ]
    const currentCounry=useSelector((state)=>state.locationList.value)
    const [selected, setSelected] = useState();
    const [selectedCurrency,setSelectedCurrency]=useState(null)
    const [selectedLanguage,setSelectedLanguage]=useState(null)
    const [value,setValue]=useState()

    const [currencies, setCurrencies] = useState([]);

    
  
    useEffect(() => {
      fetchCurrencies();
    }, []);

  

    const currencyData = Object.entries(currencyCodes).map(([countries,currencyCode]) => ({
      countries,
      currencyCode
      
    }));

    // useEffect(()=>{
    //     if(currentCounry !== undefined){
    //       localStorage.setItem("country",currentCounry)
    //     }
    // },[currentCounry])
 
        useEffect(() => {
      if (currentCounry && currencies) {
        // setSelected(currentCounry);
        setSelected(country)
        const currencyDataa = currencyData?.find(currency => currency?.countries === currentCounry);
        setSelectedCurrency(currencyDataa || null);
      }
    }, [currentCounry, currencies,country]);


   

   
 
  
  
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get('https://openexchangerates.org/api/currencies.json');
        const currencies = Object.entries(response.data).map(([code, name]) => ({ code, name }));
        setCurrencies(currencies);
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    const handleLanguageSelect = (e, newValue) => {
      localStorage.setItem("ln",newValue?.code)
      setSelectedLanguage(newValue);
      // i18n.changeLanguage(newValue?.code);
    };

    useEffect(()=>{
      if (language) {
        setSelectedLanguage(languageList?.find(ln => ln?.code === language));
        console.log(selectedLanguage);
        i18n.changeLanguage(language);
      

      } else {
        setSelectedLanguage(languageList[0]);
        i18n.changeLanguage("en");
      }
     

    },[language])

    // useEffect(() => {
    //   const elementsToChange = document.querySelectorAll('p, h1, h2,span');
    
     
    //   elementsToChange.forEach((element) => {
    //     if (selectedLanguage?.code === 'ar') {
    //       element.style.direction = 'rtl';
    //     } else {
    //       element.style.direction = 'ltr';
    //     }
    //   });
    // }, [selectedLanguage]);

  
   


  return (
    <div className='top-bar-select'>
        <div className='top-bar-select-sub'>
        <div style={{width:"20%"}}>
          <ReactFlagsSelect
           selected={selected}
           onSelect={code => {
            changeBaseUrl(code)
            setSelected(code)
            localStorage.setItem("country",code)
           
           }
            
          }
           searchable
           searchPlaceholder="Search countries"
           showSelectedLabel={false}
           showOptionFlag={true}
           placeholder=""

      />
        </div>
        <div style={{width:"20%",cursor:"pointer"}} className='top-bar-select-sub-autocomplete'>
         <Autocomplete
                       
              
                options={languageList || []}
                getOptionLabel={(option) => `${option?.name}-${option?.ln}`}
                renderInput={(params) => (
                <TextField
                {...params}
                
                />
                
                )}
                value={selectedLanguage}
                onChange={handleLanguageSelect}
                renderClearButton={() => null}

                          
            /> 
             

        </div>
        <div style={{width:"20%"}} className='top-bar-select-sub-autocomplete'>
        <Autocomplete
                       
                       options={currencyData || []}
                       getOptionLabel={(option) => option.currencyCode}
                       renderOption={(props, option) => (
                        <li {...props} key={option?.currencyCode}>
                          <span>{option?.currencyCode}</span>
                        </li>
                      )}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter((option) =>
                          option?.currencyCode?.toLowerCase()?.includes(inputValue.toLowerCase())
                        );
                      }}
                       value={selectedCurrency}
                       onChange={(event, newValue) => setSelectedCurrency(newValue)}
                       renderInput={(params) => <TextField {...params} onChange={(e)=>setValue(e.target.value)} />}
                       disabled
                          
            />

        </div>
        <p style={{width:"20%"}}>{t("Customer Support")}</p>
        </div>
       

    </div>
   
  )
}
