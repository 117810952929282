import React, { useEffect, useRef } from "react";
import "../Css/Topbar/topbar.css";
import Logo from "../Assets/Images/eyet-logo-b.png";
import UseravatarSvg from "../Assets/Icons/Useravatar.svg";
import ContainedButton from "./Custom Components/ContainedButton";
import OutlinedButton from "./Custom Components/OutlinedButton";
import UseravatarblackSvg from "../Assets/Icons/Useravatarblack.svg";
import ShoppingCartSvg from "../Assets/Icons/shopping-cart(1).svg";
import ClipboardSvg from "../Assets/Icons/clipboard.svg";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import changePassIcon from "../Assets/Icons/reload.svg";
import logoutIcon from "../Assets/Icons/logout.svg";
import cart from "../Assets/Images/cart.png";
import "aos/dist/aos.css";
import Aos from "aos";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { viewCartAPICall } from "../API/Cart/cartAPI";
import jwtDecode from "jwt-decode";
import userImage from "../Assets/Images/New/Nastroyka.png"
import { useTranslation } from 'react-i18next';
import countryList from "../Assets/JSON/countryListWithCode.json"
const TopBar = (props) => {
  const country=localStorage.getItem("country")
  const { t } = useTranslation();
  const { isRendered } = props;
  let navigate = useNavigate();
  const cartData = useSelector((state) => state.packageList.cartValue);
  const token = localStorage.getItem("userToken");
  const currentCounry=useSelector((state)=>state.locationList.value)
  const profileShowRef = useRef(null);
  const profileMobileViewRef = useRef(null);

  const checkLogined = localStorage.getItem("isLogged");
  const [show, setShow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [cartTotal, setCartTotal] = useState(false);
  const [isModify, setIsModify] = useState(false);

  const updateListner = () => {
    setIsModify(!isModify);
  };

  function handleClick() {
    navigate("/signIn");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktoProfile() {
    navigate("/profile");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktomyPlans() {
    navigate("/profileinfomyplans");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktoChangepass() {
    navigate("/profileinfochangepass");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktoMyCart() {
    navigate("/profileinfomycart");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktoHome() {
    navigate("/");
    setMenuOpen(false);
    setShow(false);
  }
  function handleClicktoProducts() {
    navigate("/products");
    setMenuOpen(false);
    setShow(false);
  }
  function clickDemo() {
    navigate("/freeTrial");
    setMenuOpen(false);
    setShow(false);
  }
  const clickLogout = () => {
    setIsLogin(false);
    setShow(false);
    setMenuOpen(false);
    navigate("/signIn");
    localStorage.clear();
  };

  const toggleMenuBar = () => {
    setMenuOpen(!menuOpen);
  };
  
  useEffect(() => {
    Aos.init({ duration: 700 });
  }, []);

  useEffect(() => {
    checkLogined === "true" ? setIsLogin(true) : setIsLogin(false);
  }, [checkLogined]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileShowRef.current &&
        !profileShowRef.current.contains(event.target)
      ) {
        setShow(false);
      }
      if (
        profileMobileViewRef.current &&
        !profileMobileViewRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    token !== null && currentCounry !== undefined &&
    viewCartAPICall({ cusId: jwtDecode(token)?._id });
  }, [token, isModify, isRendered,currentCounry]);

  useEffect(() => {
    const countryExists = countryList.filter(item => item.code === country);
    let fullList =  cartData?.packages?.filter(obj=>obj?.country === countryExists[0]?.name)
    setCartTotal(fullList?.length);
  }, [cartData]);
  return (
    <div className="topbar-parent-main">
    <div className="topbar-parent">
    
      <img src={Logo} alt="Logo" className="logo" onClick={handleClicktoHome} />
      <div className="top-bar-responsive-switch">
        {menuOpen ? (
          <CloseIcon
            onClick={toggleMenuBar}
            sx={{ fontSize: "2rem", cursor: "pointer", zIndex: 3 }}
          />
        ) : (
          <MenuIcon
            onClick={toggleMenuBar}
            sx={{ fontSize: "2rem", cursor: "pointer" }}
          />
        )}
        {menuOpen ? (
          <div
            ref={profileMobileViewRef}
            className="top-bar-parent-responsive-switch-menu"
            data-aos="fade-left"
          >
            <div className="top-bar-menu-ctn">
              <div className="top-bar-menu-section" onClick={handleClicktoHome}>
              {t("home")}
              </div>
              <div
                className="top-bar-menu-section"
                onClick={handleClicktoProducts}
              >
                {t("products")}
              </div>
              <div
                className="top-bar-menu-section"
                onClick={handleClicktoMyCart}
              >
                {t("cart")}
              </div>
              {/* {isLogin ? ( */}
                <>
                  <div
                    className="top-bar-menu-section"
                    onClick={handleClicktoProfile}
                  >
                    {t("profile")}
                  </div>
                  <div
                    className="top-bar-menu-section"
                    onClick={handleClicktomyPlans}
                  >
                   {t("myplans")}
                  </div>
                  <div
                    className="top-bar-menu-section"
                    onClick={handleClicktoChangepass}
                  >
                    {t("ChangePassword")}
                  </div>
                  <button
                    className="profile-user-container-btn"
                    onClick={clickLogout}
                  >
                    <img
                      src={logoutIcon}
                      alt="logout"
                      className="profile-menu-icon"
                    />
                    <p className="profile-menu-right">{t("logout")}</p>
                  </button>
                </>
              {/* ) : ( */}
                <>
                  <div className="top-bar-menu-section top-bar-menu-btn">
                    <ContainedButton
                       label={t("signin")}
                      onClick={handleClick}
                      width="75%"
                      marginLeft="0"
                    />
                  </div>
                </>
              {/* )} */}
              <div className="top-bar-menu-section top-bar-menu-btn">
                <OutlinedButton
                  label={t("freeTrial")}
                  onClick={clickDemo}
                  width="75%"
                  marginLeft="0"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="top-bar-parent-right" style={{justifyContent:isLogin?"end":"space-between"}}>
        <div className="nav-menu-container">
          <ul>
            <li>
              <HashLink smooth to="/#top">
                {t("home")}
              </HashLink>
            </li>
            <li>
              <HashLink smooth to="/products#top">
                {t("products")}
              </HashLink>
            </li>
            <li>
              <NavLink to="/profileinfomycart">
                <div className="top-bar-cart-container">
                  <div style={{ display: "flex" }}>
                    {cartTotal > 0 && (
                      <div className="top-bar-cart-round">
                        <p>{cartTotal}</p>
                      </div>
                    )}

                    <img
                      src={cart}
                      alt=""
                      style={{ margin: "0px 8px -3px 0" }}
                    />
                  </div>
                  {t("cart")}
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className={isLogin ? "cta-btn-login-container" : "cta-btn-container"}
        >
          <div className="profile-user">
            {show ? (
              <div ref={profileShowRef} className="profile-user-container">
                <button
                  className="profile-user-container-btn"
                  onClick={handleClicktoProfile}
                >
                  <img
                    src={UseravatarblackSvg}
                    alt="profileicon"
                    className="profile-menu-icon"
                  />
                  <p className="profile-menu-right">{t("profile")}</p>
                </button>
                {/* <button className="profile-user-container-btn" onClick={handleClicktoMyCart}>
                    <img src={ShoppingCartSvg} alt="shoppingcart" className="profile-menu-icon"/>
                     <p className="profile-menu-right">Cart</p>
                  </button> */}
                <button
                  className="profile-user-container-btn"
                  onClick={handleClicktomyPlans}
                >
                  <img
                    src={ClipboardSvg}
                    alt="myplans"
                    className="profile-menu-icon"
                  />
                  <p className="profile-menu-right">{t("myplans")}</p>
                </button>
                <button
                  className="profile-user-container-btn"
                  onClick={handleClicktoChangepass}
                >
                  <img
                    src={changePassIcon}
                    alt="changepassword"
                    className="profile-menu-icon"
                  />
                  <p className="profile-menu-right">{t("ChangePassword")}</p>
                </button>
                <button
                  className="profile-user-container-btn"
                  onClick={clickLogout}
                >
                  <img
                    src={logoutIcon}
                    alt="logout"
                    className="profile-menu-icon"
                  />
                  <p className="profile-menu-right">{t("logout")}</p>
                </button>
              </div>
            ) : null}
          </div>
          {isLogin ? (
            <button className="profile-user-btn" onClick={() => setShow(!show)}>
              <img
                className="userimage"
                src={userImage}
                alt="profileuser"
              />
            </button>
           ) : ( 
            <>
              <ContainedButton
                label={t("signin")}
                onClick={handleClick}
                width="40%"
              />
              <OutlinedButton label={t("freeTrial")} onClick={clickDemo} width="40%"  />            
            </>
           )} 
        </div>
      </div>
    
    </div>
    </div>
  );
};

export default TopBar;
