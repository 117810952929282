import React from 'react'
import '../../../Css/Login/customInput.css'
export const CustomInput = (props) => {
  const {label,className,type,value,id,onChange,onKeyDown,refer}=props
  return (
    <div className={`custom-login-input ${className}`}>
        <p>{label}</p>
        <input 
        type={type} 
        value={value} id={id} onChange={onChange} 
        onKeyDown={onKeyDown} 
        ref={refer}/>
    </div>
  )
}
