import axios from "axios";
import { HEADERS } from "../UrlAndPaths";

export const submitDemoRequestAPICall=async(body,postFn,setSnackBarStates)=>{
    await axios.post('customer/registerDemo',body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                postFn()
                setSnackBarStates((prevSnackBarStates) => ({ ...prevSnackBarStates,snackMsg:"Registered succesfully for Demo", success: true }))            
            }
        })
        .catch((err)=>{
            setSnackBarStates((prevSnackBarStates) => ({ ...prevSnackBarStates,snackMsg:"Something went wrong", error: true }))            
        })
}




export const customerQueryAPICall=async(body,postFn,setSnackBarStates,setIsLoading)=>{
    await axios.post('customer/customerQuery',body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                postFn()
                setSnackBarStates((prevSnackBarStates) => ({ ...prevSnackBarStates,snackMsg:"Thank you for your message. We will get back to you shortly.", success: true }))            
                setIsLoading(false)
            }
        })
        .catch((err)=>{
            setIsLoading(false)
            setSnackBarStates((prevSnackBarStates) => ({ ...prevSnackBarStates,snackMsg:"Something went wrong!!", error: true }))            
        })
}

