import axios from "axios";
import { HEADERS } from "../UrlAndPaths";
import jwtDecode from "jwt-decode";
export const loginUserAPICall=async(body,navigateToHome,setIsLoading,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg)=>{
    await 
    axios.post("customer/customerLogin",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                console.log("login success");
                localStorage.setItem("userToken", res.data.token);
                localStorage.setItem("isLogged", true);
                // localStorage.setItem("userId", jwtDecode(res.data.token)?._id);
                axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${res.data.token}`;
                navigateToHome()
                localStorage.setItem("companyCode",res.data.userDetails.companyCode)
                setIsLoading(false)
                
            }
        })
        .catch((err)=>{
            console.error(err);
            setIsLoading(false)
            setSnackMsg(err.response.data.msg);

          console.log(err.response.data.msg);
            // switch (err.response.status) {
            //   case 404:
            //     setSnackMsg("No data available..");
            //     break;
            //   case 500:
            //     setSnackMsg("Server error!!");
            //     break;
            //   case 401:
            //     setSnackMsg("Unauthorized!!");
            //     break;
            //   case 403:
            //     setSnackMsg("Incorrect password!!");
            //     break;
      
            //   default:
            //     setSnackMsg(
            //       err.msg
            //   );
            //   break;
            //     }

              setOpenErrorSnackbar(true)
        });
};


export const changePassAPICall=async(body,
                                    setOpenSuccessSnackbar,
                                    setOpenErrorSnackbar,
                                    setSnackMsg,clearStates,setOldPasswordAlert,setIsLoading)=>{
    await axios.post("customer/changePassword",body)
            .then((res)=>{
                if(res.status===200){
                    console.log("password changed");
                    setSnackMsg("Password changed successfully")
                    setOpenSuccessSnackbar(true)
                    clearStates()
                    setIsLoading(false)
                }
            })
            .catch((err)=>{
                console.error(err.res);
                switch (err.response.status) {
                    case 404:
                      setSnackMsg("No data available..");
                      break;
                    case 500:
                      setSnackMsg("Server error!!");
                      break;
                    case 401:
                      setSnackMsg("Unauthorized!!");
                      break;
                    case 403:
                      setSnackMsg("Incorrect password!!");
                      setOldPasswordAlert(true)
                      break;
            
                    default:
                      setSnackMsg(
                        "Unseen error occurred,Please contact to EYET Support!!!!"
                    );
                    break;
                }
                setOpenErrorSnackbar(true)
                setIsLoading(false)
            })
}

// country detection api call

