import React, { useEffect, useState } from "react";
import "../../../Css/Home/freeTrial.css"
import "aos/dist/aos.css"
import Aos from "aos";
import mobileCode from "../../../Assets/JSON/countryListWithCode.json"
import { Autocomplete,TextField } from "@mui/material";
import SuccessSnackBar from "../../Custom Components/SuccessSnackBar";
import ErrorSnackBar from "../../Custom Components/ErrorSnackBar";
import { submitDemoRequestAPICall } from "../../../API/Demo/demoAPI";
import { today } from "../../JS/Date";
import freeDemoman from "../../../Assets/Images/New/Untitled-1@1x_1-1.png"
import demoimage from "../../../Assets/Images/New/Demo 2.png"
import { useTranslation } from 'react-i18next';
export const FreeTrial=()=>{
    const { t } = useTranslation();

    const [snackBarStates,setSnackBarStates]=useState({
        success:false,
        error:false,
        snackMsg:"",
    })
    
    const handleCloseSuccess=()=>()=>{
        setSnackBarStates({...snackBarStates,error:false,success:false})
    }
    const [demoInfoBody,setDemoInfoBody]=useState({
        email:"",
        mobile:"",
        businessname:"",
        firstname:"",
        lastname:"",
        zipcode:"",
        erptype:null,
        mobileCode:null
    })

    const getDemoInfo=(key)=>(e,newValue)=>{
        const {value}=e.target;
        if(key==="email"){
            setDemoInfoBody({...demoInfoBody,email:value})
        }
        if(key==="mobile"){
            setDemoInfoBody({...demoInfoBody,mobile:value})
        }
        if(key==="businessname"){
            setDemoInfoBody({...demoInfoBody,businessname:value})
        }
        if(key==="firstname"){
            setDemoInfoBody({...demoInfoBody,firstname:value})
        }
        if(key==="lastname"){
            setDemoInfoBody({...demoInfoBody,lastname:value})
        }
        if(key==="mobileCode"){
            setDemoInfoBody({...demoInfoBody,mobileCode:newValue})
        }
        if(key==="zipcode"){
            setDemoInfoBody({...demoInfoBody,zipcode:value})
        }
        if(key==="erptype"){
            setDemoInfoBody({...demoInfoBody,erptype:newValue})
        }
    }


    const requestDemoBody={
        email:demoInfoBody.email,
        mobile:Number(`${demoInfoBody.mobileCode?.dial_code}${demoInfoBody.mobile}`),
        businessname:demoInfoBody.businessname,
        firstname:demoInfoBody.firstname,
        lastname:demoInfoBody.lastname,
        zipcode:demoInfoBody.zipcode,
        erptype:demoInfoBody.erptype,
        date:today
    }

    const clearFn=()=>{
        setDemoInfoBody({...demoInfoBody,
            email:"",
            mobile:"",
            businessname:"",
            firstname:"",
            lastname:"",
            zipcode:"",
            erptype:null,
            mobileCode:null
            })
    }
    const clickGetDemo=()=>{
        submitDemoRequestAPICall(requestDemoBody,clearFn,setSnackBarStates)
    }

    useEffect(()=>{
        Aos.init({ duration:1000});
      },[]);

      const filterOptions = (options, { inputValue }) => {
        const lowerCasedInput = inputValue.toLowerCase();
        return options.filter(option =>
          option.dial_code.includes(lowerCasedInput)
        );
      };

      const language=localStorage.getItem("ln")

useEffect(()=>{
 const elementsToChange = document.querySelectorAll('p, h1, h2,span');
 elementsToChange.forEach((element) => {
   if (language === 'ar') {
     element.style.direction = 'rtl'; // Right-to-left text direction for Arabic language
   } else {
     element.style.direction = 'ltr'; // Left-to-right text direction for other languages
   }
 });
},[language])


    return(
        <>
         {/* <TopBar/> */}
         <div className="free-trial-parent-container">
            <div className="free-demo-man-image">
              <img src={freeDemoman} alt="" />
            </div>
           
              <div className="free-trial-section" data-aos="fade-right" style={{alignSelf:"center"}}>
                <h1 className="demo-header">{t("freedemohead")}</h1>
                <p className="free-trial-left-para">{t("freedemoheaddes")}</p>
                {/* <p className="free-trial-left-para">
                Schedule a demo or get in touch with our sales team to learn how EYE-T can transform your organization.
                </p> */}
              </div>
              <div className="free-demo-image">
                <img src={demoimage} alt="" />
              </div>
            {/* <div className="free-trial-section"> */}
                <div className="free-trial-blue-ctn">
                    <h1 className="demo-blue-header">{t("Get a Free Demo")}</h1>
                      <div style={{display:"flex",flexWrap:"wrap"}}>
                    <div className="free-demo-input custom-login-input auto-complete" style={{width:"75%"}}>
                        <p>{t("erpType")}</p>
                        <Autocomplete
                         options={["Textile ERP","Restaurant ERP"]}
                         getOptionLabel={(option)=>option}
                         renderInput={(params)=>(
                            <TextField {...params} />
                         )}
                         value={demoInfoBody.erptype}
                         onChange={getDemoInfo("erptype")}                        
                        />
                    </div>
                    <div className="free-demo-input">
                        <p>{t("email")}</p>
                        <input type="email"
                        onChange={getDemoInfo("email")}
                        value={demoInfoBody.email}
                        />
                    </div>
                    {/* <div className="free-demo-input">
                        <p>Business Name</p>
                        <input type="text"/>
                    </div> */}
                    <div className="free-demo-input">
                        <p>{t("firstname")}</p>
                        <input type="name"
                        onChange={getDemoInfo("firstname")}
                        value={demoInfoBody.firstname}
                        />
                    </div>
                    <div className="free-demo-input">
                        <p>{t("lastname")}</p>
                        <input type="name"
                        onChange={getDemoInfo("lastname")}
                        value={demoInfoBody.lastname}
                        />
                    </div>
                    <div className="free-demo-input">
                        <p>{t("companyname")}</p>
                        <input type="text"
                        onChange={getDemoInfo("businessname")}
                        value={demoInfoBody.businessname}
                        />
                    </div>
                    <div className="free-demo-input">
                        <p>{t("phone")}</p>
                    <div className="free-demo-mobile-input auto-complete">
                        <Autocomplete
                         style={{ width: 100 }}
                         options={mobileCode}
                         getOptionLabel={(option)=>option?.dial_code}
                         renderInput={(params)=>(
                            <TextField {...params}/>
                         )}
                         renderOption={(props, option) => (
                            <li {...props} key={option?.dial_code}>
                              <span>{option?.dial_code}</span>
                            </li>
                          )}
                        filterOptions={filterOptions}
                         onChange={getDemoInfo("mobileCode")}
                         vaalue={demoInfoBody.mobileCode}
                        />
                        <input type="tel"
                        onChange={getDemoInfo("mobile")}
                        value={demoInfoBody.mobile}
                        />
                    </div>
                    </div>
                    <div className="free-demo-input">
                        <p>{t("zipcode")}</p>
                        <input type="text"
                        onChange={getDemoInfo("zipcode")}
                        value={demoInfoBody.zipcode}
                        />
                    </div>
                    </div>

                    <button
                    onClick={clickGetDemo}
                    className="btn btn-get-demo">{t("Get Demo")}</button>
                 </div>                
            {/* </div> */}

         </div>

         <SuccessSnackBar
            open={snackBarStates.success}
            handleClose={handleCloseSuccess()}
            message={snackBarStates.snackMsg}
         />
         <ErrorSnackBar
            open={snackBarStates.error}
            handleClose={handleCloseSuccess()}
            message={snackBarStates.snackMsg}
         />
        </>
    )
}