import { createSlice } from "@reduxjs/toolkit";

export const checkOutSlice=createSlice({
    name:"checkOut",
    initialState:{
        value:undefined,
    },
    reducers:{
        get_checkOut_first:(state,action)=>{
            state.value=action.payload.checkOutData
        },
       
    }
})
export const {get_checkOut_first}=checkOutSlice.actions;
export default checkOutSlice?.reducer