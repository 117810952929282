import React, {useRef, useState} from 'react'
import logo from '../../../Assets/Images/eyet-logo-b.png'
import { CustomInput } from './CustomInput'
import { LoginButton } from './LoginButton'
import { LoginFooter } from './LoginFooter'
import { loginUserAPICall } from '../../../API/Login/loginUserAPI'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/joy/Button';
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar'
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next';
// css from login.css
export const LoginCard = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [status,setStatus]=useState('login')
  
  const companyCodeRef=useRef(null)
  const passwordRef=useRef(null)
  const userNameRef=useRef(null)

  const[errorLabelMobile,setErrorLabelMobile]=useState(false)
  const[errorLabelCompany,setErrorLabelCompany]=useState(false)
  const[errorLabelPass,setErrorLabelPass]=useState(false)

  const[isLoading,setIsLoading]=useState(false)
  // snackbar state
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!");
  const [passwordView,setPasswordView]=useState(false)
  const loginInfoFormInitialState={
    companyCode:'',
    username:'',
    password:'',
  }
  const [loginInfoForm,setLoginInfoForm]=useState(loginInfoFormInitialState)
  const getLoginInfo =(key)=>(e)=>{
    const{value}=e.target;
    if(key==="companyCode"){
      setLoginInfoForm({...loginInfoForm,companyCode:value})
      setErrorLabelCompany(false)
    }
    if(key==="userName"){
      setLoginInfoForm({...loginInfoForm,username:value})
      setErrorLabelMobile(false)
    }
    if(key==="password"){
      setLoginInfoForm({...loginInfoForm,password:value})
      setErrorLabelPass(false)
    }
  }
  const closeSuccessSnackbar=()=>{
    setOpenSuccessSnackbar(false)
  }

  const closeErrorSnackbar=()=>{
  setOpenErrorSnackbar(false)
  }
  const handleChange =()=>{
    if(loginInfoForm?.companyCode===""){
      setErrorLabelCompany(true)
    }
    else{
      setStatus('user')
    }
  }

  function handleClicktoHome(){
    navigate('/profileinfomyplans')
    window.location.reload();
  }

  const clickSignUp=()=>{
    // if(loginInfoForm?.companyCode===""){
    //   setErrorLabelCompany(true)
    // }
     if(loginInfoForm?.username===""){
      setErrorLabelMobile(true)
    }
    else if(loginInfoForm?.password===""){
      setErrorLabelPass(true)
    }
    else{
    setIsLoading(true)
    loginUserAPICall(loginInfoForm,handleClicktoHome,setIsLoading,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg)
    }
  }

  const handleKeyDown=(e)=>{
      if(e.key==="Enter"){
        e.preventDefault()
        if(e.target===companyCodeRef.current){
          handleChange()
          userNameRef.current.focus()
        } else if(e.target===userNameRef.current){
          passwordRef.current.focus()
        } else if(e.target===passwordRef.current){
          clickSignUp()
        }
      }
  }

  const passwordViewFn=()=>{
    setPasswordView(!passwordView)
  }

  return (
    <>
  {/* {status==='login'?
    <div className="login-card-container">
        <div className="login-card-top-container">
          <img src={logo}/>
        </div>
        <div className="login-card-top-container">
          <h2>Welcome!</h2>
          <p>Sign in to your account</p>
        </div>
        <div className="login-card-form-container">
          <div className={`custom-login-input`}>
            <p>Company Code</p>
            <input ref={companyCodeRef} type="text" value={loginInfoForm?.companyCode}  
            onChange={getLoginInfo("companyCode")}
            onKeyDown={handleKeyDown}
            />
            {errorLabelCompany? 
            <label className="custom-input-validation" style={{left:"0"}}>
              Please fill these field !!</label>
          :""}  
          </div>
        </div>
         <div className="login-card-button-container"> 
        
          <LoginButton label="Next" onClick={handleChange}/>
          <LoginFooter/>
        
        </div> 
      
    </div>
    :
    status === 'user' ? */}
    <div className="login-card-container">
    {/* <div className="login-card-top-container">
      <img src={logo}/>
    </div> */}
    <div className="login-card-top-container">
          <h2 style={{fontSize:"1.7rem"}}>{t("welcome")}</h2>
          <p style={{fontSize:"1.1rem"}}>{t("signintoyouraccount")}</p>
        </div>
    <div className="login-card-form-container">
        {/* <div className={`custom-login-input`}>
          <p>Company Code</p>
          <input ref={companyCodeRef} type="text" 
          value={loginInfoForm?.companyCode}
          onKeyDown={handleKeyDown}
          onChange={getLoginInfo("companyCode")}/>
          {errorLabelCompany? 
            <label className="custom-input-validation" style={{left:"0"}}>
              Please fill these field !!</label>
          :""}  
        </div> */}
        <div className={`custom-login-input`} style={{marginTop:"4%"}}>
          <p>{t("username")}</p>
          <input type="text"
          ref={userNameRef}
          onKeyDown={handleKeyDown}
          value={loginInfoForm?.username}  onChange={getLoginInfo("userName")}/>
          {errorLabelMobile? 
            <label className="custom-input-validation" style={{left:"0"}}>
              {t("pleasefillthesefield")}</label>
          :""}
        </div>
        <div className={`custom-login-input`} style={{position:"relative",marginTop:"4%"}}>
          <p>{t("password")}</p>
          <input type={passwordView?"text":"password" }
          ref={passwordRef}
          onKeyDown={handleKeyDown}
          value={loginInfoForm?.password}  onChange={getLoginInfo("password")}/>
          {errorLabelPass? 
            <label className="custom-input-validation" style={{left:"0"}}>
              {t("pleasefillthesefield")}</label>
          :""}
          <IconButton onClick={passwordViewFn} style={{position:"absolute",bottom:"-10px",right:"22px"}}>
              {passwordView ? (
                            <i class="bi bi-eye-slash visibility-icon"></i>
                          ) : (
                            <i class="bi bi-eye visibility-icon"></i>
                )}
          </IconButton>
        </div>
  
    </div>
     <div className="login-card-button-container"> 
    
     <div className="new-sign-in-button-green" style={{marginTop:"6%"}}>
      <Button loading={isLoading} onClick={clickSignUp}>
        {t("signin")}
      </Button>
      {/* <button className='login-button' style={{cursor:"pointer"}} onClick={clickSignUp}>Sign up</button> */}
    </div>
      <LoginFooter/>
    
    </div>
    {/* <div className='login-card-footer-container'>
   </div> */}
  
  </div>

   {/* :"" } */}

  <SuccessSnackBar open={openSuccessSnackbar} handleClose={closeSuccessSnackbar} message={snackMsg}/>
  <ErrorSnackBar open={openErrorSnackbar} handleClose={closeErrorSnackbar} message={snackMsg}/>
    
    </>
  )
}
