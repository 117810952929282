import { createSlice } from "@reduxjs/toolkit";

const getAllsmsPackageSlice = createSlice({
    name:'getAllsmsPackageSlice',
    initialState:{
        value:undefined,
        whatsapp:undefined
    },
    reducers:{
        get_All_sms_Package:(state,action)=>{
            state.value = action.payload.getSmsPackage;
           
        },
        get_all_whatsapp_package:(state,action)=>{
            state.whatsapp=action.payload.getWhatsappPackage
        }
    }
});

export const {get_All_sms_Package,get_all_whatsapp_package} = getAllsmsPackageSlice.actions;
export default getAllsmsPackageSlice.reducer;