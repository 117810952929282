import {configureStore} from "@reduxjs/toolkit";
import registerUserSliceReducer from "./Login/registerUserSlice"
import profileInfoSliceReducer from "./Profile/profileInfoSlice";
import packageReducer from "./Package/packageSlice"
import cartReducer from "./Package/cartSlice"
import smsPackageReducer from "./smsPackage/viewSmsPackageSlice"
import myPlansReducer from "./Profile/myPlansSlice";
import geoLocationReducer from "./Login/geoLocationSlice"
import countryListSliceReducer from "./Country/countryListSlice";
import checkOut from "../Components/Pages/Profile/checkoutSlice"
export default configureStore({
    reducer: {
        registerUserSlice:registerUserSliceReducer,
        profileInfoSlice:profileInfoSliceReducer,
        packageList:packageReducer,
        cartSlice:cartReducer,
        getAllsmsPackageSlice:smsPackageReducer,
        myPlansSlice:myPlansReducer,
        locationList:geoLocationReducer,
        countryListSlice:countryListSliceReducer,
        checkOut

        
    },
});