import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
// import { AES} from 'crypto-js';
import { checksum, decrypt, encrypt } from './aes';
import { addCustomerPurchase, addCustomerPurchaseAPICall } from '../../../API/Payment/PaymentAPI';



export const PaymentCheckoutProcessPage = (props) => {
  const {subTotal} = props
   const firstFormData = useSelector((state)=>state.checkOut.value)

   

//// new code
const key = firstFormData?.key;
const meid = firstFormData?.meid;
const amount = firstFormData?.amount;
// const key = "202402150001"
// const meid = "lC7IcC3/wiqxs3wN0ksW0JLAxdnR8NhRm42k7LIofQk="
// const amount = subTotal




const txnDetails = {
  meId: meid,
  merchantOrderNo: Math.floor(Math.random() * 90000) + 10000,
  amount: amount,
  countryCode: "ARE",
  currencyCode: "AED",
  txnType: "SALE",
  channel: "WEB",
  userId: "sani@antino.io",
  planId: "",
  SuccessUrl: "/Response.jsp",
  FailUrl: "/Response.jsp"
};
const customerDetails = {
  name: "jsCybsOn",
  email: "successful.payment@tabby.ai",
  phone: "500000001",
  uniqueId: ""
};
const billingDetails = {
  bill_address: "123",
  bill_city: "Gurgaon",
  bill_state: "Hariyana",
  bill_country: "India",
  bill_zip: "110038"
};
const shipDetails = {
  ship_address: "",
  ship_city: "",
  ship_state: "",
  ship_country: "",
  ship_zip: "",
  ship_days: "",
  address_count: ""
};

const itemDetails = {
  item_count: "",
  item_value: "",
  item_category: ""
};

const otherDetails = {
  udf_1: "",
  udf_2: "",
  udf_3: "",
  udf_4: "",
  udf_5: "",
  udf_6: ""
};
const orderDetails = {
  txnDetails: txnDetails,
  customerDetails: customerDetails,
  billingDetails: billingDetails,
  shipDetails: shipDetails,
  itemDetails: itemDetails,
  otherDetails: otherDetails
};





const compute = `${txnDetails.meId}|${txnDetails.merchantOrderNo}|${txnDetails.amount}|${txnDetails.countryCode}|${txnDetails.currencyCode}`;

const test = JSON.stringify(orderDetails);
// const test = orderDetails;

 const encryptedData = encrypt(test,key); 


const hash =  checksum(compute);


const paymode = '';

      


const jsCheckoutOptions = {
  merchantId: txnDetails.meId,
  // internalKey:txnDetails?.key
};

const spObj = new JsCheckout();
// const spObj=window.JsCheckout;
// spObj.Init(jsCheckoutOptions);

const handlePayment=()=>{

  // spObj.Pay("",encryptedData, hash, JSON.stringify(orderDetails), CallbackForResponse);
 
  spObj.Pay(paymode,encryptedData,hash, JSON.stringify(orderDetails), CallbackForResponse);
}

const CallbackForResponse = (response) => {
    console.log(response);       
    
};



useEffect(() => {
  const script = document.createElement("script");
  script.src = `https://uatcheckout.safexpay.ae/ms-transaction-core-1-0/jscheckout/js-checkoutNew.js`;
  script.async = true;
  script.onload = () => {
    /* global JsCheckout */
    spObj.Init(jsCheckoutOptions);
  };
  document.body.appendChild(script);
  return () => {
    document.body.removeChild(script);
  };
},[]);




  // Listen for the custom event on the document
  document.addEventListener('transactionEvent', function (event) { 
    const payload = event.detail;  
    const decryptedData = decrypt(payload, key); 
    const decryptobj=JSON.parse(decryptedData)
    console.log(decryptobj?.status);
    if(decryptobj?.status === "Successful"){
      console.log("nihad");
      addCustomerPurchaseAPICall();
    }
    console.log("transactionEvent", payload); 
    console.log("decryptdata",typeof(decryptobj));
  });
  

 






  return (
    <div style={{width:"60%"}}>
        {/* <button onClick={handlePayment} className='buy-now-button-cart' style={{width:"63%"}}>Buy Now</button> */}
         <h3>JS Checkout Process</h3>
         <div className="containerIn">
         <label for="buy">Click button to process Order No </label>
         <button  onClick={handlePayment} className="buy">Pay Now11111</button>
       </div>

    </div>

   
  )
}
