import axios from "axios"
// import { HEADERS } from "../UrlAndPaths"
import store from "../../Redux/store"
import { get_all_package_for_website, get_filter_package, get_package_list } from "../../Redux/Package/packageSlice"
import { get_All_sms_Package, get_all_whatsapp_package } from "../../Redux/smsPackage/viewSmsPackageSlice"
import { HEADERS, HEADER_QUERY } from "../UrlAndPaths"
import { viewCartAPICall } from "../Cart/cartAPI"


// get all package
export const packageAPICall=()=>{
    axios.post("package/getAllPackages",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_package_list({packageData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

//  get sms package
export const viewAllSmsPackageAPICall=()=>{
    axios.get("admin/getSmsPackages",HEADERS)
    .then((res)=>{
      if(res.status===200){
        console.log('get data');
        store.dispatch(get_All_sms_Package({getSmsPackage:res.data}))
      }
    })
    .catch((err)=>{
      console.log(err.response); 
    })
  }

  export const viewAllWhatsappPackageAPICall=()=>{
    axios.get("admin/getwhatsappPackages",HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(get_all_whatsapp_package({getWhatsappPackage:res.data}))
      }
    })
    .catch((err)=>{
      console.log(err.response);
    })
  }

  export const deletePackageAPICall=(cusId,_id,updateListener,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg)=>{
    axios.delete("customer/deletecart",{
      headers: HEADER_QUERY,
      data: {
        _id:cusId,
        packageId:_id,
      },
    })
    .then((res)=>{
       if(res.status===200){
        updateListener()
        setSnackMsg('Package Deleted')
        setOpenSuccessSnackbar(true)
       
        // setSnackBarStates({...snackBarStates,success:true,snackMsg:"Package Deleted"})
       
       }
    })
    .catch((err)=>{
       console.log(err.response);
       setOpenErrorSnackbar(true)
       setSnackMsg('Error')
      //  setSnackBarStates({...snackBarStates,error:true,snackMsg:err.response.data})
    })
  }

  // filter package
  export const filterPackgeAPICall=(body)=>{
    axios.post("package/filtererppackage",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_filter_package({filterPackageData:res.data}))
        }
    })
    .catch((err)=>{
      console.log(err.response);
    })
  }

  export const geAllPackageForCustomerWebsite=(body)=>{
    axios.post("package/getPackagesForCustomerWebsite",{},HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(get_all_package_for_website({packageCustomerData:res.data}))
      }
    })
    .catch((err)=>{
      console.log(err.response);
    })
  }