import React, { useState } from "react";
import '../../../Css/Profile/profileinfochangepass.css'
import lockImg from "../../../Assets/Images/New/Reset Password.png"
import { changePassAPICall } from '../../../API/Login/loginUserAPI'
import SuccessSnackBar from '../../Custom Components/SuccessSnackBar'
import ErrorSnackBar from '../../Custom Components/ErrorSnackBar'
import Button from '@mui/joy/Button';
import { forgotPassSendOTPAPICall, forgotPassVerifyOTPAPICall, resetPasswordAPICall } from "../../../API/Login/forgotPassAPI";
export const ForgotPass=()=>{

    const passwordFormInitialState={
        companyCode:"",
        userId:"",
        otp:"",
        newPass:"",
        confirmPass:"",
    }
    const [passwordForm,setPasswordForm]=useState(passwordFormInitialState)

    const [otpSent,setOtpSent]=useState(false)
    const [otpVerified,setOtpVerified]=useState(false)
    // snackbar state
    const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [snackMsg, setSnackMsg] = useState("oops!");
    const[isLoading,setIsLoading]=useState(false)

    const [companyCodeAlert,setCompanyCodeAlert]=useState(false)
    const [userNameAlert,setUserNameAlert]=useState(false)
    const [otpAlert,setOtpAlert]=useState(false)
    const [newPassAlert,setNewPassAlert]=useState(false)
    const [confirmPassAlert,setConfirmPassAlert]=useState(false)

    const getPasswordInfo=(key)=>(e)=>{
        const{value}=e.target
        if(key==="companyCode"){
            setPasswordForm({...passwordForm,companyCode:value})
            setCompanyCodeAlert(false)
        }
        if(key==="userId"){
            setPasswordForm({...passwordForm,userId:value})
            setUserNameAlert(false)
        }
        if(key==="otp"){
            setPasswordForm({...passwordForm,otp:value})
            setOtpAlert(false)
        }
        if(key==="newPass"){
            setPasswordForm({...passwordForm,newPass:value})
            setNewPassAlert(false)
        }
        if(key==="confirmPass"){
            setPasswordForm({...passwordForm,confirmPass:value})
            setConfirmPassAlert(false)
        }
    }
    const closeSuccessSnackbar=()=>{
        setOpenSuccessSnackbar(false)
    }
    const closeErrorSnackbar=()=>{
    setOpenErrorSnackbar(false)
    }

    const clearStates=()=>{
        setPasswordForm(passwordFormInitialState)
    }
    const clickSendOtp=()=>{
        if(passwordForm?.companyCode===""){
            setCompanyCodeAlert(true)
        }
        else if(passwordForm?.userId===""){
            setUserNameAlert(true)
        }
        else{
            setIsLoading(true)
            // forgotPassSendOTPAPICall({
            //     username:passwordForm.userId,
            //     companyCode:passwordForm.companyCode
            // },setOtpSent,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading)
            setOtpSent(true)
        }
    }
    const clickVerifyOtp=()=>{
        if(passwordForm.otp===""){
            setOtpAlert(true)
        }
        else{
            setIsLoading(true)
            forgotPassVerifyOTPAPICall({
                username:passwordForm.userId,
                companyCode:passwordForm.companyCode,
                otp:passwordForm.otp
            },setOtpVerified,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading)
        }
    }

    const clickSubmitNewPass=()=>{
        if(passwordForm?.newPass===""){
            setNewPassAlert(true)
        }
        else if(passwordForm?.newPass!==passwordForm?.confirmPass){
            setConfirmPassAlert(true)
        }
        else{
            setIsLoading(true)
            resetPasswordAPICall({newPassword:passwordForm?.newPass},setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading,clearStates)
        }
    }
    return(
        <>
        <div className="change-pass-ctn">
            <div className="change-pass-ctn-right">
                <img src={lockImg} alt="" />
            </div>
            <div className="change-pass-ctn-left">            
                <div className="change-pass-head">
                    <h2>Forgot Password</h2>
                </div>

                {
                    !otpVerified?
                    <>
                    <div className="change-pass-body">
                        <div className="change-pass-body-section">
                            <p>Company code</p>
                            <input type="text" value={passwordForm?.companyCode} onChange={getPasswordInfo("companyCode")} disabled={otpSent}/>
                            {companyCodeAlert? 
                                <label className="custom-input-validation" style={{left:"0"}}>
                                fill this field !!</label>
                            :""}  
                        </div>
                        <div className="change-pass-body-section">
                            <p>User Id</p>
                            <input type="text" value={passwordForm?.userId} onChange={getPasswordInfo("userId")} disabled={otpSent}/>
                            {userNameAlert? 
                                <label className="custom-input-validation" style={{left:"0"}}>
                                fill this field !!</label>
                            :""}
                        </div>

                        {

                        otpSent?
                        <div className="change-pass-body-section">
                            <p>OTP</p>
                            <input type="tel" value={passwordForm?.otp} onChange={getPasswordInfo("otp")}/>                
                            {otpAlert? 
                                <label className="custom-input-validation" style={{left:"0"}}>
                                Fill this field !!</label>
                            :""}  
                        </div>     
                        :""
                        }
                    </div>
                    <div className="change-pass-btn">
                        <Button loading={isLoading} onClick={otpSent?clickVerifyOtp:clickSendOtp}>{otpSent?"verify":"Send OTP"}</Button>
                        {/* <button onClick={otpSent?clickVerifyOtp:clickSendOtp}>{otpSent?"verify":"Send OTP"}</button> */}
                    </div>
                    </>
                    :
                    <>
                        <div className="change-pass-body">
                        <div className="change-pass-body-section">
                            <p>New Password</p>
                            <input type="password" value={passwordForm?.newPass} onChange={getPasswordInfo("newPass")}/>
                            {newPassAlert? 
                                <label className="custom-input-validation" style={{left:"0"}}>
                                fill this field !!</label>
                            :""}  
                        </div>
                        <div className="change-pass-body-section">
                            <p>Confirm Password</p>
                            <input type="password" value={passwordForm?.confirmPass} onChange={getPasswordInfo("confirmPass")}/>
                            {confirmPassAlert? 
                                <label className="custom-input-validation" style={{left:"0"}}>
                                Password doesn't match!!</label>
                            :""}
                        </div>
                       </div>
                    <div className="change-pass-btn">
                        <Button loading={isLoading} onClick={clickSubmitNewPass}>Submit</Button>
                        {/* <button onClick={clickSubmitNewPass}>Submit</button> */}
                    </div>
                    </>
                }
                
            </div>
          
        </div>    


        <SuccessSnackBar open={openSuccessSnackbar} handleClose={closeSuccessSnackbar} message={snackMsg}/>
        <ErrorSnackBar open={openErrorSnackbar} handleClose={closeErrorSnackbar} message={snackMsg}/>
    
        </>
    )
}