import React, { useState } from 'react'
import "./PaymentCheckoutPage.css"

import { useNavigate } from 'react-router-dom';
import store from '../../../Redux/store';
import { get_checkOut_first } from './checkoutSlice';
export const PaymentCheckoutPage = () => {
   const navigateTo=useNavigate()



    // const onSubmit = (event) => {
    //   event.preventDefault();
    //   console.log(this.state);
  
    //   const cipherText = AES.encrypt(this.state.message, this.state.secret);
    //   this.setState({ cipher: cipherText.toString(), message: '' });
    // }
    // const secretKey = 'EFFd2acI34ArHBhZH1kiHdz8ceSL2swZSXRDnPnvsrk';
   // const skey = 'EFFd2acI34ArHBhZH1kiHdz8ceSL2swZSXRDnPnvsrk'; 
  //  const base64Iv = "0123456789abcdef"; 
  //  const key = CryptoJS.enc.Base64.parse(skey); 
  //  const iv  = CryptoJS.enc.Base64.parse(base64Iv); 
  //  const payButtonFn=()=>{
      // const cipherText = AES.encrypt(data, secretKey);
      // const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
      // console.log(data);
      // console.log(JSON.stringify(data));
    //  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    //    iv: iv,
   //     mode: CryptoJS.mode.CBC,
   //     padding: CryptoJS.pad.Pkcs7,
   //   });
   //   const encryptedText = encrypted.toString();
   //   console.log(encryptedText);

   //   const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
   //     iv: iv,
   ////     mode: CryptoJS.mode.CBC,
   //     padding: CryptoJS.pad.Pkcs7,
   //   });
      
    //  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    //  console.log("Decrypted Data:", decryptedText);
   // }



  const [formData, setFormData] = useState({
      meid: '202402150001',
      key: 'lC7IcC3/wiqxs3wN0ksW0JLAxdnR8NhRm42k7LIofQk=',
      amount: '',
      uniqueId: '',
      planId: '997336434' // Default planId
   });

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
  };

  console.log(formData);

  const handleSubmit =() => {
    store.dispatch(get_checkOut_first({checkOutData:formData}))
     navigateTo("/profileinfomycart/checkout/process")

}
 





 

  return (
    
 

        <div>
        <h3>Checkout Form</h3>
        <div className="container-checkout">
        <div>
          <label >Id <span style={{color: 'red'}}>*</span></label>
          <input type="text"  name="meid" placeholder="Merchant Id..." value={formData.meid} onChange={handleChange} required />

          <label>Key <span style={{color: 'red'}}>*</span></label>
          <input type="text"  name="key" placeholder="Encryption Key..." value={formData.key} onChange={handleChange} required />

          <label >Amount <span style={{color: 'red'}}>*</span></label>
          <input type="text"  name="amount" placeholder="Amount..." value={formData.amount} onChange={handleChange} required />

          <label >UniqueId</label>
          <input type="text"  name="uniqueId" placeholder="uniqueId..." value={formData.uniqueId} onChange={handleChange} />

          <label >planId</label>
          <input type="text"  name="planId" placeholder="planId..." value={formData.planId} onChange={handleChange} />

          {/* <input type="submit" value="Submit" /> */}
          <button onClick={handleSubmit}>Submit</button>
        </div>
        </div>
        </div>
  )
}
