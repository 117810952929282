import React from "react";
import "../../Css/Custom Components/buttons.css";
const OutlinedButton = (props) => {
  const {label,backgroundColor,fontSize,fontWeight,onClick,width,marginLeft}=props
  return (
    <>
    <button className="btn cta-outlined-btn" onClick={onClick}style={{width:width,marginLeft:marginLeft}}>
      {label}
    </button>   
    </>
  )
};

export default OutlinedButton;
