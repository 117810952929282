import React from 'react'
import TopBar from '../../TopBar'
import '../../../Css/Login/login.css'
import loginImage from '../../../Assets/Images/New/Group 10962.svg'
import { LoginCard } from './LoginCard'
import { useSelector } from 'react-redux'
import backgroundImage from "../../../Assets/Images/New/Path 1902.png"

export const Login = () => {
 
  return (
    <>
      {/* <TopBar/> */}
      
      <div className='login-container'>
        {/* <div className='login-background-image-container'>
        <img src={backgroundImage} alt=""/>
        </div> */}
        <div className='login-sub-container'>
     
         <div  className='login-right-container'>
          <img  src={loginImage}/>
         
         </div>
         <div  className='login-left-container'>
          <LoginCard/>
          
          </div>
        </div>
    
      </div>
    
    </>
  )
}
