import axios from "axios";
import { HEADERS } from "../../UrlAndPaths";
import store from "../../../Redux/store"
import { get_profile_list } from "../../../Redux/Profile/profileInfoSlice";
export const getUserInfoAPICall=async(body)=>{
    await axios.post('customer/getSingleCustomer',body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(
                    get_profile_list({profileInfoData:res.data})
                    )
            }
        })
        .catch((err)=>{
            store.dispatch(get_profile_list({profileInfoData:undefined}))
        })
}