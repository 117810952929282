import {createSlice} from "@reduxjs/toolkit";

export const profileInfoSlice =createSlice({
        name:'profileInfoSlice',
        initialState:{
            value:undefined
      },
      reducers:{
          get_profile_list:(state,action)=>{
              state.value=action.payload.profileInfoData;
            }
      } 
    })
    
    export const {get_profile_list}=profileInfoSlice.actions
    export default profileInfoSlice.reducer