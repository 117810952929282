import Swal from "sweetalert2";
import "../../Css/Custom Components/alertMessage.css"
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import axios from "axios";
import { HEADERS } from "../../API/UrlAndPaths";
import store from "../../Redux/store";
import { get_previous_country_list } from "../../Redux/Country/countryListSlice";

export const warningAlertMessage=(title,text,myPlansNavigationFn,type)=>{
  
    Swal.fire({
        title:title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#000',
        cancelButtonColor: '#fff',
        confirmButtonText:type==="cart"?'Yes':'Ok',
        customClass:{
           text:"warning-alert-text"
        }
      }).then((result) => {
        if (result.isConfirmed) {
        //   Swal.fire(
        //     'Deleted!',
        //     'Your file has been deleted.',
        //     'success'
        //   )
          myPlansNavigationFn()
        }

      })
}

export const addPrevCountryAPICall=async(body)=>{
  await axios.post("admin/addcountry",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        // store.dispatch(get_previous_country_list({prevCountry:res.data}))
        console.log("added")
      }
    })
    .catch((err)=>{
    })
}
export const getPrevCountryAPICall=async()=>{
  await axios.post("admin/getcountry",{},HEADERS)
    .then((res)=>{
      if(res.status===200){
        store.dispatch(get_previous_country_list({prevCountry:res.data}))
      }
    })
    .catch((err)=>{
      store.dispatch(get_previous_country_list({prevCountry:undefined}))
    })
}
export const alertMessageFn=(currentCounry)=>{




  // let navigate = useNavigate();
  console.log(currentCounry);
  Swal.fire({
    position: 'top',
    title: "Below Packages doesn't match your current location",
    text:`Would You Like To Redirect To ${currentCounry?.country_name}`,
    color:"#fff",
    showConfirmButton: true,
    background:"linear-gradient(to right,#0026F2,#001379)",
    customClass:{
      title:"alert-popup-title",
      text:"alert-popup-text",
      confirmButton:"alert-popup-button-ok"
    }
  })
  .then((result) => {
    if (result.isConfirmed) {
      
      if(currentCounry?.country_name==="India"){
        // addPrevCountryAPICall({country:currentCounry?.country_name})
        //  window.location.replace('http://localhost:3000/');
         window.location.replace('https://eyeterp.com/');
         document.cookie = 'currency' + "=" + currentCounry?.currency;
       
       }
      //  if(currentCounry?.country_name==="France"){
      //   window.location.replace('http://localhost:3001/');
      //   document.cookie = 'currency' + "=" + currentCounry?.currency;
       
      //  }
      else{
        // window.location.replace('http://localhost:3002/');
        window.location.replace('https://eyeterp.ae/');
        document.cookie = 'currency' + "=" + currentCounry?.currency;
      }
    }
   
  })
}