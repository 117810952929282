import axios from "axios";
import { HEADERS } from "../UrlAndPaths";

export const forgotPassSendOTPAPICall=async(body,otpSent,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading)=>{
    await axios.post("customer/sendOtp",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setSnackMsg("OTP sent to registered e-mail")
                setOpenSuccessSnackbar(true)
                otpSent(true)
                setIsLoading(false)
            }
        })
        .catch((err)=>{
            setIsLoading(false)
            switch (err.response.status) {
                case 404:
                    setSnackMsg("User not found")
                    break;
            
                default:
                    setSnackMsg("something went wrong")
                    break;
            }
            setOpenErrorSnackbar(true)
            console.error(err.res);
        })
}
export const forgotPassVerifyOTPAPICall=async(body,otpVerified,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading)=>{
    await axios.post("customer/verifyOtp",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setIsLoading(false)
                setSnackMsg("Verification succesfull")
                setOpenSuccessSnackbar(true)
                axios.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${res.data.token}`;

                  otpVerified(true)
            }
        })
        .catch((err)=>{
            setIsLoading(false)
            switch (err.response.status) {
                case 403:
                    setSnackMsg("Incorrect OTP!!")
                    break;
                case 404:
                    setSnackMsg("OTP expired")
                default:
                    setSnackMsg("something went wrong")
                    break;
            }
            setOpenErrorSnackbar(true)
            console.error(err.res);
        })
}

export const resetPasswordAPICall=async(body,setOpenSuccessSnackbar,setOpenErrorSnackbar,setSnackMsg,setIsLoading,clearStates)=>{
    await axios.post("customer/resetPassword",body,HEADERS)
        .then((res)=>{
            if(res.status===200){
                setSnackMsg("Password changed succesfully")
                setOpenSuccessSnackbar(true)
                setIsLoading(false)
                clearStates()
            }
        })
        .catch((err)=>{
            console.error(err.response);
            setIsLoading(false)
            
            setOpenErrorSnackbar(true)
        })
}