import axios from "axios"
import store from "../../Redux/store";
import { get_geoLocation_list } from "../../Redux/Login/geoLocationSlice";
import { changeBaseUrl } from "../UrlAndPaths";


export const geoLocationAPICall=()=>{
    const country=localStorage.getItem("country")
    axios.get("https://ipapi.co/json/")
    .then((res)=>{
        if(country){
            // localStorage.setItem("country",res.data?.country)
             changeBaseUrl(country)
             localStorage.setItem("country",country)
        }
        else{
            changeBaseUrl(res.data?.country)
            localStorage.setItem("country",res.data?.country)
        }
        
        
    })
    .catch((err)=>{
        console.log(err);
    })
}