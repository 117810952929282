import React from "react";
import TopBar from "../../TopBar";
import "../../../Css/Home/home.css";
import ContainedButton from "../../Custom Components/ContainedButton";
import GirlImg from "../../../Assets/Images/girl.png";
import AdminDashImg from "../../../Assets/Images/admin-dash.png";
import EmpDashImg from "../../../Assets/Images/empdash.png";
import LoginImg from "../../../Assets/Images/login.png";
import TabImg from "../../../Assets/Images/Tab.png";
import LightVioletCircle from "../../../Assets/Images/Subtraction 8.png";
import PurpleCircle from "../../../Assets/Images/Subtraction 3.1.png";
import BlueCircle from "../../../Assets/Images/Subtraction 2.1.png";
import OrangeCircle from '../../../Assets/Images/Subtraction 9.png'
import PinkCircle from '../../../Assets/Images/Subtraction 3.2.png'
import LightPinkCircle from '../../../Assets/Images/Subtraction 7.png'
import LightOrangeCircle from '../../../Assets/Images/Subtraction 2.png'
import RightArrowSvg from "../../../Assets/Icons/arrow-right.svg";
import { ProductCard } from "../../Custom Components/ProductCard";
import LogisticsSvg from "../../../Assets/Icons/Logistics.svg";
import HotelSvg from "../../../Assets/Icons/hotel_1_.svg";
import EducationSvg from "../../../Assets/Icons/mortarboard.svg";
import RestaurantSvg from "../../../Assets/Icons/Restuarent.svg";
import { motion } from "framer-motion";
import { Footer } from "../../Footer";
import { CustomInput } from "../Login/CustomInput";
export const ProfileInfoHome = () =>{
    //TODO Make cards horizontally scrollable
    const scrollToLeft = () => {
      const scrollDiv = document.getElementById("purchaseProductCardContainer");
      scrollDiv.scrollBy(200,0)
    };
    return(
        <>
       {/* <TopBar /> */}
      {/*  First CTA Section -section 1 */}
      <section className="first-cta-section" id="firstCta">
        <div className="first-cta-left-wrapper">
          <div className="first-cta-left-desc-container">
            <h1>Handle business with ease</h1>
            <p>
              EYE-T provides innovative ERP solutions to handle everyday
              business operations. EYE-T has been satisfying customers since
              2009 throughout the Middle East. Now spreading worldwide to make
              business easier
            </p>
          </div>
          <div className="first-cta-left-btn-container">
            <ContainedButton label="Purchase" fontWeight="600" />
            <button className="demo-cta">Schedule Demo</button>
          </div>
        </div>
        <div className="first-cta-right-wrapper">
          <motion.div
            initial={{ rotate: 15 }}
            whileHover={{ scale: 1.1, rotate: 0 }}
            exit={{ rotate: 15 }}
            className="cta-right-img-container admin-img"
          >
            <img src={AdminDashImg} alt="Admin dashboard" />
          </motion.div>
          <motion.div
            initial={{ rotate: 348 }}
            whileHover={{ scale: 1.1, rotate: 360 }}
            exit={{ rotate: 348 }}
            className="cta-right-img-container emp-dash-img"
          >
            <img src={EmpDashImg} alt="Employee dashboard" />
          </motion.div>
          <motion.div
            initial={{ rotate: 348 }}
            whileHover={{ scale: 1.1, rotate: 360 }}
            exit={{ rotate: 348 }}
            className="cta-right-img-container login-img"
          >
            <img src={LoginImg} alt="Login Page" />
          </motion.div>
        </div>
      </section>

      {/* Second CTA Section -section 2 */}
      <section id="secondCta" className="second-cta-section">
        <div className="second-cta-description-container">
          <div className="second-cta-left-container second-cta-child">
            <img src={GirlImg} alt="Girl with laptop" />
          </div>
          <div className="second-cta-right-container second-cta-child">
            <h2>Bringing a revolutionary change in the ERP world</h2>
            <p>
              EYE-T has been satisfying customers since 2009 throughout the
              Middle East. Now spreading worldwide to make business easier
            </p>
            <button>
              <p>SIGN UP</p> <img src={RightArrowSvg} alt="right arrow" />
            </button>
          </div>
        </div>
      </section>
      
    <section
            id="purchaseProductsSection"
            className="purchase-products-section-parent"
        >
            <h2>Purchase Products</h2>
            <p className="purchase-prod-description">
            EYE-T is a complete set of integrated ERP that will help entire
            business organizations effectively manage their businesses. Which
            includes Accounting, Human Resources Management, Inventory Management,
            Sales, Purchase, etc.
            </p>
            <motion.div
            id="purchaseProductCardContainer"
            className="purchase-products-card-container"
            >
            <ProductCard
                backgroundColor="#ff0009"
                Url={LogisticsSvg}
                buttonLabel="Purchase"
                heading="Logistics"
                description="Lorem Ipsum has been the industry's standard dummy 
    text ever since the 1500s, when an unknown printer 
    took a galley of type and scrambled it to make."
                className="purchase-prod-first-card"
            />
            <ProductCard
                backgroundColor="#ff8800"
                Url={HotelSvg}
                buttonLabel="Purchase"
                heading="Hotel"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
            />
            <ProductCard
                backgroundColor="#004cff"
                Url={EducationSvg}
                buttonLabel="Purchase"
                heading="Education"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
            />
            <ProductCard
                backgroundColor="#ff00cc"
                Url={RestaurantSvg}
                buttonLabel="Purchase"
                heading="Restaurant"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
            />
            <ProductCard
                backgroundColor="#04969b"
                Url={LogisticsSvg}
                buttonLabel="Purchase"
                heading="Logistics"
                description="Lorem Ipsum has been the industry's standard dummy 
    text ever since the 1500s, when an unknown printer 
    took a galley of type and scrambled it to make."
                className="purchase-prod-first-card"
            />
            <ProductCard
                backgroundColor="#e3eb00"
                Url={HotelSvg}
                buttonLabel="Purchase"
                heading="Hotel"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
            />
            </motion.div>
            <button
            className="purchase-product-view-more-btn"
            onClick={scrollToLeft}
            >
            {" "}
            <p>View More</p> <img src={RightArrowSvg} alt="right arrow" />
            </button>
        </section>
        {/* SECTION 4 */}
        <section id="homeSectionFour" className="home-section-four-parent">
            <div className="section-four-content-container">
            <div className="section-four-content-child image">
                <div className="circle-container">
                {/* demo container to fix background circle  */}
                </div>
                <img src={TabImg} alt="eye-t dashboard" />
            </div>
            <div className="section-four-content-child description">
                <h2>Lorem Ipsum is simply dummy text</h2>
                <p>
                Lorem Ipsum has been the industry's standard dummy text ever since
                the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not
                only five centuries,
                </p>
                <button>
                <p>SIGN UP</p> <img src={RightArrowSvg} alt="right arrow" />
                </button>
            </div>
            </div>
        </section>

        {/* SECTION 5- Easy Purchase  */}
        <section id="homeSectionFive" className="home-section-five-parent">
            <div className="section-five-content-container">
            <div className="section-five-left-container">
                <h2>Easy Purchase</h2>
                <p>
                Lorem Ipsum has been the industry's standard dummy text ever since
                the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not
                only five centuries,
                </p>
                <button>
                <p>SIGN UP</p> <img src={RightArrowSvg} alt="right arrow" />
                </button>
            </div>
            <div className="section-five-right-container">
                <img
                src={PurpleCircle}
                alt="eye-t erp "
                className="purple-circle"
                />
                <img src={BlueCircle} alt="eye-t erp" className="blue-circle" />
                <img src={TabImg} alt="purchase eye-t" />
            </div>
            </div>
        </section>

        {/* SECTION 6 - Track Sales transaction ---  copied from section 4 -- css also from there */}
        <section id="homeSectionSix" className="home-section-four-parent">
            <div className="section-four-content-container">
            <div className="section-four-content-child image">
                <div className="circle-container orange">
                {/* demo container to fix background circle  */}
                </div>
                <img src={TabImg} alt="eye-t dashboard" />
            </div>
            <div className="section-four-content-child description">
                <h2>Track Sales Transaction</h2>
                <p>
                Lorem Ipsum has been the industry's standard dummy text ever since
                the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not
                only five centuries,
                </p>
                <button>
                <p>SIGN UP</p> <img src={RightArrowSvg} alt="right arrow" />
                </button>
            </div>
            </div>
        </section>

        {/* SECTION 7 - ERP Features */}
        <section id="homeSectionSeven" className="home-section-seven-parent">
            <div className="home-section-seven-parent-container">
            <h2>ERP Features</h2>
            <div className="home-section-7-features-container">
                <ProductCard
                backgroundColor="#ff0009"
                Url={LogisticsSvg}
                heading="INVENTORY"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy 
    text ever since the 1500s, when an unknown printer 
    took a galley of type and scrambled it to make."
                className="purchase-prod-first-card"
                />
                <ProductCard
                backgroundColor="#ff8800"
                Url={HotelSvg}
                heading="PURCHASE"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
                />
                <ProductCard
                backgroundColor="#004cff"
                Url={RestaurantSvg}
                heading="ACCOUNTING"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
                />
                <ProductCard
                backgroundColor="#ff00cc"
                Url={EducationSvg}
                heading="HR & PAYROLL"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
                />
                <ProductCard
                backgroundColor="#04969b"
                Url={LogisticsSvg}
                heading="POINT OF SALE"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy 
    text ever since the 1500s, when an unknown printer 
    took a galley of type and scrambled it to make."
                className="purchase-prod-first-card"
                />
                <ProductCard
                backgroundColor="#e3eb00"
                Url={HotelSvg}
                heading="SALES"
                buttonLabel="Read More"
                description="Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer
    took a galley of type and scrambled it to make.
    "
                />
            </div>
            </div>
        </section>
        {/* SECTION 8 - HOW CONTROL ERP   */}
        <section id="homeSectionEight" className="home-section-eight-parent">
            <div className="section-eight-parent-container">
            <div className="section-eight-left-container">
                <h2>Check out how Control ERP & learn workflow</h2>
                <p>
                Lorem Ipsum has been the industry's standard dummy text ever since
                the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not
                only five centuries,
                </p>
            </div>
            <div className="section-eight-right-container">
                <img src={ OrangeCircle} alt="eye-t" className="sec8-orange-circle" />
                <img src={ PinkCircle} alt="eye-t erp" className="sec8-pink-circle"/>
                <iframe
                width="1268"
                height="713"
                src="https://www.youtube.com/embed/SPs_wqsUs6w"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                ></iframe>
            </div>
            </div>
        </section>

        {/* SECTION 9- Query  */}
        <section id="homeSectionNine" className="home-section-nine-parent">
            <div className="home-section-nine-container">
            <h2>Have a Query?</h2>
            <div className="home-section-form-container">
                <div className="single-input">
                <p>Your Name</p>
                <input type="text" />
                </div>
                <div className="single-input">
                <p>Your Email</p>
                <input type="email" />
                </div>
                <div className="single-input">
                <p>Phone</p>
                <input type="email" />
                </div>
                <div className="single-input">
                <p>Message</p>
                <textarea name="" id=""/>
                </div>
            
            </div>
            <div className="form-submit-btn-container">
                <button>SEND MESSAGE</button>
            </div>
            <img src={LightPinkCircle} alt="EYET" className="query-pink"/>
            <img src={LightOrangeCircle} alt="EYET" className="query-orange"/>
            </div>
        </section>

        {/* Footer Section  */}
        <Footer />
        </>
    );
    };

    export default ProfileInfoHome;